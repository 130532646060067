import React from 'react'

import Banner from './banner'
import Propaganda from './propaganda'
// import About from './about'
// import Depoimentos from './depoimentos'
// import Clientes from './marcas-clientes'
// import Cursos from './cursos'

const PaginaInicial = () => {
  return (
    <>
      <Banner />

      <Propaganda/>

      {/* <Cursos /> */}

      {/* <About /> */}

      {/* <Depoimentos /> */}

      {/* <Clientes /> */}
    </>
  )
}

export default PaginaInicial

// @ts-nocheck
import React, { useContext } from 'react'
import {
  HOME,
  AMBIENTE_INTERNO,
  MANTER_CURSO,
  MANTER_CATEGORIA,
  MANTER_TURMA,
  GERENCIAR_CERTIFICADOS
} from 'routes'
import { BannerDefault } from 'ui'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { AuthContext } from 'contexts/auth'

const AmbienteInterno = () => {
  const { logout } = useContext(AuthContext)

  return (
    <>
      <BannerDefault>
        <h1 className='text-white'>Ambiente Interno</h1>
        <p className='text-white link-nav'>
          <Link to={HOME}>Home</Link>
          <span className='lnr lnr-arrow-right' />
          <Link to={AMBIENTE_INTERNO}>Ambiente Interno</Link>
        </p>
      </BannerDefault>

      <section className='post-content-area single-post-area'>
        <div className='container'>
          <button className='genric-btn primary radius mb-30' onClick={logout}>
            {' '}
            Logout{' '}
          </button>

          <h4 className='mb-30'>
            <Link to={MANTER_CATEGORIA}>Manter Categorias dos Cursos</Link>
          </h4>
          <h4 className='mb-30'>
            <Link to={MANTER_CURSO}>Manter Cursos</Link>
          </h4>
          <h4 className='mb-30'>
            <Link to={MANTER_TURMA}>Manter Agenda das Turmas</Link>
          </h4>
          <h4 className='mb-30'>
            <Link to={GERENCIAR_CERTIFICADOS}>Certificado de Participação</Link>
          </h4>
        </div>
      </section>
    </>
  )
}

export default AmbienteInterno

import React, { useEffect, useContext } from 'react'
import { BannerDefault } from 'ui'
import { Link } from 'react-router-dom'
import { HOME, MANTER_CURSO, AMBIENTE_INTERNO } from 'routes'

import TabelaCurso from 'pages/ambiente-interno/manter-curso/tabela-curso'
import { CursoContext } from 'contexts/cursos'

const ManterCurso = () => {
  const { cursos, listarCursos } = useContext(CursoContext)

  useEffect(() => {
    listarCursos()
  }, [listarCursos])

  return (
    <>
      <BannerDefault>
        <h1 className='text-white'>
          Ambiente Interno
        </h1>
        <p className='text-white link-nav'>
          <Link to={HOME}>Home</Link>
          <span className='lnr lnr-arrow-right' />
          <Link to={AMBIENTE_INTERNO}>Ambiente Interno</Link>
          <span className='lnr lnr-arrow-right' />
          <Link to={MANTER_CURSO}>Manter Cursos</Link>
        </p>
      </BannerDefault>

      <section className='post-content-area single-post-area'>
        <div className='container'>
          <TabelaCurso
            cursos={cursos}
          />
        </div>
      </section>
    </>
  )
}

export default ManterCurso

// @ts-nocheck
import React, { useContext, useState, useEffect } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import t from 'prop-types'
import { CursoContext } from 'contexts/cursos'
import { CategoriaContext } from 'contexts/categorias'
import api from 'services/api'
import { formatReal } from 'utils/mask'
import ModalEdtEmenta from './ementas/edt-ementa'
import TabelaEmenta from './ementas/tabela-ementa'
import ModalAddEmenta from './ementas/add-ementa'

const ModalEdtCurso = ({ modal, toggle, cursoAtual, ementasCurso }) => {
  const { listarCursos, listarEmentasPorCurso } = useContext(CursoContext)
  const { listarCategorias, categorias } = useContext(CategoriaContext)

  const [cursoInfo, setCursoInfo] = useState({
    nome: '',
    descricao: '',
    preco: '',
    categoria: '',
    modalidade: '',
    publicoAlvo: '',
    cargaHoraria: '',
    objetivo: '',
    metodologia: ''
  })

  const [ementaAtual, setEmentaAtual] = useState({})
  const [openAddModal, setOpenAddModal] = useState(false)
  const [openEdtModal, setOpenEdtModal] = useState(false)

  const toggleAdd = () => setOpenAddModal(!openAddModal)
  const toggleEdt = () => setOpenEdtModal(!openEdtModal)

  const [error, setError] = useState(false)

  useEffect(() => {
    listarCategorias()

    const curso = {
      nome: cursoAtual.nome,
      descricao: cursoAtual.descricao,
      preco: formatReal(cursoAtual.preco),
      categoria:
        cursoAtual.categoria !== undefined
          ? cursoAtual.categoria.idCategoria
          : '',
      modalidade: cursoAtual.modalidade,
      publicoAlvo: cursoAtual.publicoAlvo,
      cargaHoraria: cursoAtual.cargaHoraria,
      objetivo: cursoAtual.objetivo,
      metodologia: cursoAtual.metodologia
    }
    setCursoInfo(curso)
  }, [listarCategorias, cursoAtual])

  const handleAddEmenta = (ementa) => {
    setError(false)
    const body = {
      ementas: [ementa],
      idCurso: cursoAtual.idCurso
    }
    api.post('/ementas', body).then((response) => {
      listarEmentasPorCurso(cursoAtual.idCurso)
      toggleAdd()
    })
  }

  const handleEdtEmenta = (e, id) => {
    e.preventDefault()
    api.get(`/ementas/${id}`).then((response) => {
      setEmentaAtual(response.data)
      toggleEdt()
    })
  }

  const handleEditarEmenta = (ementa) => {
    api.put(`/ementas/${ementa.idEmenta}`, ementa).then(() => {
      listarEmentasPorCurso(cursoAtual.idCurso)
      toggleEdt()
    })
  }

  const handleRemoveEmenta = (e, id) => {
    e.preventDefault()
    api.delete(`/ementas/${id}`).then(() => {
      listarEmentasPorCurso(cursoAtual.idCurso)
    })
  }

  const handleEditarCurso = (e) => {
    e.preventDefault()
    e.target.reset()
    setError(false)

    const curso = {
      nome: cursoInfo.nome,
      descricao: cursoInfo.descricao,
      preco: Number(cursoInfo.preco.split(' ')[1].replace(',', '.')),
      categoria: cursoInfo.categoria,
      modalidade: cursoInfo.modalidade,
      publicoAlvo: cursoInfo.publicoAlvo,
      cargaHoraria: cursoInfo.cargaHoraria,
      objetivo: cursoInfo.objetivo,
      metodologia: cursoInfo.metodologia
    }

    if (
      (ementasCurso.length > 0,
      curso.nome &&
        curso.descricao &&
        curso.preco &&
        curso.categoria &&
        curso.modalidade &&
        curso.publicoAlvo &&
        curso.cargaHoraria &&
        curso.objetivo &&
        curso.metodologia)
    ) {
      api.put(`/cursos/${cursoAtual.idCurso}`, curso).then((response) => {
        listarCursos()
        setCursoInfo({
          nome: '',
          descricao: '',
          preco: '',
          categoria: '',
          modalidade: '',
          publicoAlvo: '',
          cargaHoraria: '',
          objetivo: '',
          metodologia: ''
        })
        toggle()
      })
    } else {
      setError(true)
    }
  }

  const handleFocus = (e) => {
    e.target.placeholder = ''
  }

  const handleBlur = (e, placeholder) => {
    e.target.placeholder = placeholder
  }

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <form onSubmit={handleEditarCurso}>
          <ModalHeader toggle={toggle}>Editar Curso</ModalHeader>
          <ModalBody>
            <div className='form-group form-inline'>
              <div className='form-group'>
                <input
                  type='text'
                  className='form-control'
                  style={{ width: '400px' }}
                  id='edt-nome-curso'
                  placeholder='Nome do Curso'
                  value={cursoInfo.nome || ''}
                  required
                  onFocus={handleFocus}
                  onBlur={(e) => handleBlur(e, 'Nome do Curso')}
                  onChange={(e) => {
                    const val = e.target.value
                    setCursoInfo((prevState) => {
                      return { ...prevState, nome: val }
                    })
                  }}
                />
              </div>
            </div>

            <div className='form-group form-inline'>
              <div className='form-group'>
                <textarea
                  className='form-control'
                  style={{ width: '400px' }}
                  id='edt-descricao-curso'
                  placeholder='Descrição do Curso'
                  value={cursoInfo.descricao || ''}
                  rows={4}
                  required
                  onFocus={handleFocus}
                  onBlur={(e) => handleBlur(e, 'Descrição do Curso')}
                  onChange={(e) => {
                    const val = e.target.value
                    setCursoInfo((prevState) => {
                      return { ...prevState, descricao: val }
                    })
                  }}
                />
              </div>
            </div>

            <div className='form-group form-inline'>
              <div className='form-group'>
                <input
                  type='text'
                  className='form-control'
                  style={{ width: '400px' }}
                  id='edt-preco-curso'
                  placeholder='Preço do Curso'
                  value={cursoInfo.preco || ''}
                  required
                  onFocus={handleFocus}
                  onBlur={(e) => handleBlur(e, 'Preço do Curso')}
                  onChange={(e) => {
                    const val = e.target.value
                    setCursoInfo((prevState) => {
                      return { ...prevState, preco: formatReal(val) }
                    })
                  }}
                />
              </div>
            </div>

            <div className='form-group form-inline'>
              <div className='form-group'>
                <div className='title text-left'>
                  <h6 className='mb-10'>Categoria</h6>
                  <div className='default-select' id='default-select'>
                    <select
                      value={cursoInfo.categoria || ''}
                      required
                      onChange={(e) => {
                        const val = e.target.value
                        setCursoInfo((prevState) => {
                          return { ...prevState, categoria: val }
                        })
                      }}
                    >
                      <option key='selecionar' value=''>
                        Selecione uma Categoria
                      </option>
                      {categorias.map((categoria, index) => (
                        <option key={index} value={categoria.idCategoria}>
                          {categoria.nome}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className='form-group form-inline'>
              <div className='form-group'>
                <div className='title text-left'>
                  <h6 className='mb-10'>Modalidade</h6>
                  <div className='default-select' id='default-select'>
                    <select
                      value={cursoInfo.modalidade}
                      required
                      onChange={(e) => {
                        const val = e.target.value
                        setCursoInfo((prevState) => {
                          return { ...prevState, modalidade: val }
                        })
                      }}
                    >
                      <option key='selecionar' value=''>
                        Selecione uma Modalidade
                      </option>
                      <option key='online' value='Online'>
                        Online
                      </option>
                      <option key='presencial' value='Presencial'>
                        Presencial
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className='form-group form-inline'>
              <div className='form-group'>
                <textarea
                  className='form-control'
                  style={{ width: '400px' }}
                  id='edt-publico-curso'
                  placeholder='Publico Alvo do Curso'
                  value={cursoInfo.publicoAlvo || ''}
                  required
                  rows={2}
                  onFocus={handleFocus}
                  onBlur={(e) => handleBlur(e, 'Publico Alvo')}
                  onChange={(e) => {
                    const val = e.target.value
                    setCursoInfo((prevState) => {
                      return { ...prevState, publicoAlvo: val }
                    })
                  }}
                />
              </div>
            </div>

            <div className='form-group form-inline'>
              <div className='form-group'>
                <input
                  type='text'
                  className='form-control'
                  style={{ width: '400px' }}
                  id='edt-carga-curso'
                  placeholder='Carga Horaria do Curso'
                  value={cursoInfo.cargaHoraria || ''}
                  required
                  onFocus={handleFocus}
                  onBlur={(e) => handleBlur(e, 'Carga Horaria do Curso')}
                  onChange={(e) => {
                    const val = e.target.value
                    setCursoInfo((prevState) => {
                      return { ...prevState, cargaHoraria: val }
                    })
                  }}
                />
              </div>
            </div>

            <div className='form-group form-inline'>
              <div className='form-group'>
                <input
                  type='text'
                  className='form-control'
                  style={{ width: '400px' }}
                  id='edt-objetivo-curso'
                  placeholder='Objetivo'
                  value={cursoInfo.objetivo || ''}
                  required
                  onFocus={handleFocus}
                  onBlur={(e) => handleBlur(e, 'Objetivo')}
                  onChange={(e) => {
                    const val = e.target.value
                    setCursoInfo((prevState) => {
                      return { ...prevState, objetivo: val }
                    })
                  }}
                />
              </div>
            </div>

            <div className='form-group form-inline'>
              <div className='form-group'>
                <textarea
                  className='form-control'
                  style={{ width: '400px' }}
                  id='edt-metodologia-curso'
                  placeholder='Metodologia Utilizada'
                  value={cursoInfo.metodologia || ''}
                  required
                  rows={4}
                  onFocus={handleFocus}
                  onBlur={(e) => handleBlur(e, 'Metodologia Utilizada')}
                  onChange={(e) => {
                    const val = e.target.value
                    setCursoInfo((prevState) => {
                      return { ...prevState, metodologia: val }
                    })
                  }}
                />
              </div>
            </div>

            <div className='form-group form-inline'>
              <div className='form-group'>
                <button
                  type='button'
                  className='genric-btn primary radius'
                  onClick={toggleAdd}
                >
                  Adicionar Ementas ao Curso
                </button>
              </div>
            </div>

            {ementasCurso.length > 0 && (
              <TabelaEmenta
                ementas={ementasCurso}
                handleEditar={handleEdtEmenta}
                handleDeletar={handleRemoveEmenta}
              />
            )}

            {error && (
              <div className='alert alert-warning' role='alert'>
                Por favor, preencha todos os campos.
              </div>
            )}

            <ModalAddEmenta
              openAddModal={openAddModal}
              toggleAddModal={toggleAdd}
              handleAddEmenta={handleAddEmenta}
            />

            <ModalEdtEmenta
              ementa={ementaAtual}
              openEdtModal={openEdtModal}
              handleEdtEmenta={handleEditarEmenta}
              toggleEdtModal={toggleEdt}
            />
          </ModalBody>
          <ModalFooter>
            <button type='submit' className='genric-btn primary radius'>
              Editar
            </button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  )
}

ModalEdtCurso.propTypes = {
  modal: t.bool,
  toggle: t.func,
  cursoAtual: t.object,
  ementasCurso: t.array
}

export default ModalEdtCurso

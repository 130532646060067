// @ts-nocheck
import React, { useContext, useState, Fragment } from 'react'
import t from 'prop-types'
import ModalAddCurso from 'pages/ambiente-interno/manter-curso/add-curso'
import { VIEW_CURSO } from 'routes'
import { useHistory } from 'react-router-dom'
import ModalEdtCurso from 'pages/ambiente-interno/manter-curso/edt-curso'
import ModalDelCurso from 'pages/ambiente-interno/manter-curso/del-curso'
import { CursoContext } from 'contexts/cursos'

const TabelaCurso = ({ cursos }) => {
  const history = useHistory()

  const { cursoAtual, buscarCurso, ementasCurso, listarEmentasPorCurso } = useContext(CursoContext)

  const [modalAdd, setModalAdd] = useState(false)
  const [nestedModalAdd, setNestedModalAdd] = useState(false)
  const [modalEdt, setModalEdt] = useState(false)
  const [modalDel, setModalDel] = useState(false)

  const toggleAdd = () => setModalAdd(!modalAdd)
  const toggleEdt = () => setModalEdt(!modalEdt)
  const toggleDel = () => setModalDel(!modalDel)

  const toggleNestedAdd = () => {
    setNestedModalAdd(!nestedModalAdd)
  }

  const handleVisualizarCurso = (e, id) => {
    history.push(VIEW_CURSO.replace(':idCurso', id))
  }

  const handleEditar = (e, id) => {
    buscarCurso(id)
    listarEmentasPorCurso(id)
    toggleEdt()
  }

  const handleDeletar = (e, id) => {
    buscarCurso(id)
    toggleDel()
  }

  return (
    <>
      <div className='section-top-border'>
        <div className='row'>
          <div className='col-md-6'>
            <h3 className='mb-30'>Cursos</h3>
          </div>

          <div className='col-md-6 text-right'>
            <button className='genric-btn primary radius' onClick={toggleAdd}>
              Adicionar Curso
            </button>
            <ModalAddCurso modal={modalAdd} nestedModal={nestedModalAdd} toggle={toggleAdd} toggleNested={toggleNestedAdd} />
          </div>
        </div>
        <table className='table'>
          <thead>
            <tr>
              <th scope='col'>Nome</th>
              <th scope='col'>Descrição</th>
              <th scope='col'>Carga Horaria</th>
              <th scope='col'>Ações</th>
            </tr>
          </thead>
          <tbody>
            {cursos.map((curso, index) => (
              <Fragment key={index}>
                <tr>
                  <td>{curso.nome}</td>
                  <td>{curso.descricao}</td>
                  <td>{curso.cargaHoraria}</td>
                  <td>
                    <button
                      onClick={(e) => handleVisualizarCurso(e, curso.idCurso)}
                      className='genric-btn info-border radius small ml-10'
                      style={{ padding: '4px', width: '50px' }}
                    >
                      <span
                        className='lnr lnr lnr-eye'
                        style={{ fontSize: '20px', margin: '2px' }}
                      />
                    </button>
                    <button
                      onClick={(e) => handleEditar(e, curso.idCurso)}
                      className='genric-btn warning-border radius small ml-10'
                      style={{ padding: '4px', width: '50px' }}
                    >
                      <span
                        className='lnr lnr-pencil'
                        style={{ fontSize: '20px', margin: '2px' }}
                      />
                    </button>
                    <button
                      onClick={(e) => handleDeletar(e, curso.idCurso)}
                      className='genric-btn danger-border radius small ml-10'
                      style={{ padding: '4px', width: '50px' }}
                    >
                      <span
                        className='lnr lnr lnr-trash'
                        style={{ fontSize: '20px', margin: '2px' }}
                      />
                    </button>
                  </td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>

      <ModalEdtCurso
        modal={modalEdt}
        toggle={toggleEdt}
        cursoAtual={cursoAtual}
        ementasCurso={ementasCurso}
      />

      <ModalDelCurso modal={modalDel} toggle={toggleDel} cursoAtual={cursoAtual} />
    </>
  )
}

TabelaCurso.propTypes = {
  cursos: t.array
}

export default TabelaCurso

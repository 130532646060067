import React from 'react'
import t from 'prop-types'

const BannerDefault = ({ children }) => {
  return (
    <section className='relative about-banner'>
      <div className='overlay overlay-bg' />
      <div className='container h-10'>
        <div className='row d-flex align-items-center justify-content-center'>
          <div className='about-content col-lg-12' style={{ padding: '3rem 0 1rem 0' }}>
            {children}
          </div>
        </div>
      </div>
    </section>
  )
}

BannerDefault.propTypes = {
  children: t.node
}

export default BannerDefault

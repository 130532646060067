// @ts-nocheck
import React, { Component } from 'react'
import t from 'prop-types'
import GoogleMapReact from 'google-map-react'
import Marcador from './marcador'

class Mapa extends Component {
  static defaultProps = {
    center: {
      lat: -12.989434,
      lng: -38.473762
    },
    zoom: 15
  }

  render () {
    return (
      <div style={{ height: '445px', width: '100%', margin: '50px' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyC3XQcA8CB_dsx5ZPHJw8jWcAg3YsS3_aQ' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <Marcador
            lat={-12.989434}
            lng={-38.473762}
            text='Parallel'
          />
        </GoogleMapReact>
      </div>
    )
  }
}

Mapa.propTypes = {
  center: t.object,
  zoom: t.number
}

export default Mapa

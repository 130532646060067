import React, { useState, useContext, useEffect } from 'react'
import t from 'prop-types'
import api from 'services/api'
import { TurmaContext } from 'contexts/turmas'
import { CursoContext } from 'contexts/cursos'
import ModalDefault from 'utils/modal-default'

const ModalAddTurma = ({ modal, toggle }) => {
  // @ts-ignore
  const { listarTurmas } = useContext(TurmaContext)
  // @ts-ignore
  const { listarCursos, cursos } = useContext(CursoContext)

  const [turmaInfo, setTurmaInfo] = useState({
    nome: '',
    dataInicio: '',
    dataFim: '',
    curso: ''
  })

  useEffect(() => {
    listarCursos()
  }, [listarCursos])

  const handleCadastrar = (e) => {
    e.preventDefault()
    e.target.reset()

    api.post('/turmas', turmaInfo)
      // @ts-ignore
      .then((response) => {
        listarTurmas()
        setTurmaInfo({
          nome: '',
          dataInicio: '',
          dataFim: '',
          curso: ''
        })
        toggle()
      })
  }

  const handleFocus = (e) => {
    e.target.placeholder = ''
  }

  const handleBlur = (e, placeholder) => {
    e.target.placeholder = placeholder
  }

  return (
    <>
      <ModalDefault
        modal={modal}
        toggle={toggle}
        titulo='Adicionar Turma'
        btnLabel='Adicionar'
        handleAction={handleCadastrar}
      >
        <div className='form-group form-inline'>
          <div className='form-group'>
            <input
              type='text'
              className='form-control'
              style={{ width: '400px' }}
              id='add-nome-turma'
              placeholder='Nome da Turma'
              value={turmaInfo.nome}
              required
              onFocus={handleFocus}
              onBlur={(e) => handleBlur(e, 'Nome da Turma')}
              onChange={(e) => {
                const val = e.target.value
                setTurmaInfo(prevState => {
                  return { ...prevState, nome: val }
                })
              }}
            />
          </div>
        </div>

        <div className='form-group form-inline'>
          <div className='form-group'>
            <input
              type='date'
              className='form-control'
              style={{ width: '400px' }}
              id='add-date-inicio-turma'
              placeholder='Data de Inicio da Turma'
              value={turmaInfo.dataInicio}
              required
              onFocus={handleFocus}
              onBlur={(e) => handleBlur(e, 'Data de Inicio da Turma')}
              onChange={(e) => {
                const val = e.target.value
                setTurmaInfo(prevState => {
                  return { ...prevState, dataInicio: val }
                })
              }}
            />
          </div>
        </div>

        <div className='form-group form-inline'>
          <div className='form-group'>
            <input
              type='date'
              className='form-control'
              style={{ width: '400px' }}
              id='add-date-fim-turma'
              placeholder='Data do Fim da Turma'
              value={turmaInfo.dataFim}
              required
              onFocus={handleFocus}
              onBlur={(e) => handleBlur(e, 'Data do Fim da Turma')}
              onChange={(e) => {
                const val = e.target.value
                setTurmaInfo(prevState => {
                  return { ...prevState, dataFim: val }
                })
              }}
            />
          </div>
        </div>

        <div className='form-group form-inline'>
          <div className='form-group'>
            <div className='title text-left'>
              <h6 className='mb-10'>Curso</h6>
              <div className='default-select' id='default-select'>
                <select
                  value={turmaInfo.curso}
                  required
                  onChange={(e) => {
                    const val = e.target.value
                    setTurmaInfo(prevState => {
                      return { ...prevState, curso: val }
                    })
                  }}
                >
                  <option key='selecionar' value=''>Selecione um Curso</option>
                  {cursos.map((curso, index) => (
                    <option key={index} value={curso.idCurso}>{curso.nome}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </ModalDefault>
    </>
  )
}

ModalAddTurma.propTypes = {
  modal: t.bool,
  toggle: t.func
}

export default ModalAddTurma

import React from 'react'
import { Link } from 'react-router-dom'
import { CURSOS } from 'routes'

const Propaganda = () => {
  return (
    <section className='banner-area relative propaganda-banner' id='home'>
      <div className='overlay overlay-bg' />
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-center'>
       
        <h1 >
        <i className="fa fa-android" aria-hidden="true"></i>  Temos APP!   
              
            </h1>
           
           
              <a href='https://play.google.com/store/apps/details?id=com.parallel.parallelcursosapp' className='primary-btn'>Baixe agora</a>

       
        </div>
      </div>
    </section>
  )
}

export default Propaganda

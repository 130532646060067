import React from 'react'
import { HOME, AMBIENTE_INTERNO, GERENCIAR_CERTIFICADOS, GERAR_CERTIFICADO, ENVIAR_CERTIFICADO } from 'routes'
import { BannerDefault } from 'ui'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const GerenciarCertificados = () => {
  return (
    <>
      <BannerDefault>
        <h1 className='text-white'>
          Ambiente Interno
        </h1>
        <p className='text-white link-nav'>
          <Link to={HOME}>Home</Link>
          <span className='lnr lnr-arrow-right' />
          <Link to={AMBIENTE_INTERNO}>Ambiente Interno</Link>
          <span className='lnr lnr-arrow-right' />
          <Link to={GERENCIAR_CERTIFICADOS}>Certificados de Participação</Link>
        </p>
      </BannerDefault>

      <section className='post-content-area single-post-area'>
        <div className='container'>
          <h3 className='mb-30'>Gerenciar Certificados de Participação</h3>

          <h4 className='mb-30'><Link to={GERAR_CERTIFICADO}>Gerar Certificado de Participação</Link></h4>
          <h4 className='mb-30'><Link to={ENVIAR_CERTIFICADO}>Enviar Certificado de Participação por Email</Link></h4>
        </div>
      </section>
    </>
  )
}

export default GerenciarCertificados

import React from 'react'
import t from 'prop-types'

const Marcador = ({ text }) => (
  <div className='icon'>
    <span className='lnr lnr-map-marker' style={{ fontSize: '30px', color: 'red' }} />
    <p style={{ fontSize: '20px' }}>{text}</p>
  </div>
)

Marcador.propTypes = {
  text: t.string
}
export default Marcador

import React from 'react'
import { Link } from 'react-router-dom'
import { HOME, CURSOS, AGENDA, INSCRICAO, CONTATO, AREA_ADMIN, PAGAMENTO } from 'routes'

const Header = () => {
  return (
    <header id='header'>
      <nav className='navbar navbar-expand-lg navbar-light bg-white'>
        <div className='container'>
          <div id='logo' className='navbar-brand'>
            <Link to={HOME}>
              <img src='img/logo.png' alt='' title='' />
            </Link>
            <div className='col-lg-2 col-md-6 col-sm-6 social-widget'>
              <div className='footer-social d-flex align-items-center'>
                <a href='https://www.facebook.com/ParallelTecnologia/' target='_blank' rel='noopener noreferrer' className='px-2'><i className='fa fa-facebook' /></a>
                <a href='https://www.instagram.com/paralleltecnologia/' target='_blank' rel='noopener noreferrer' className='px-2'><i className='fa fa-instagram' /></a>
                <a href='https://api.whatsapp.com/send?phone=+557185553564' target='_blank' rel='noopener noreferrer' className='px-2'><i className='fa fa-whatsapp' /></a>
                <a href='https://www.linkedin.com/company/parallel-consulting-&-training/' rel='noopener noreferrer' target='_blank' className='px-2'><i className='fa fa-linkedin' /></a>
              </div>
            </div>
          </div>
          <button className='navbar-toggler' type='button' data-toggle='collapse' data-target='#menu' aria-controls='menu' aria-expanded='false' aria-label='Menu Colapso'>
            <span className='navbar-toggler-icon' />
          </button>

          <div id='menu' className='collapse navbar-collapse'>
            <ul className='navbar-nav ml-auto nav-menu'>
              <li data-toggle='collapse' data-target='#menu' aria-controls='menu' aria-expanded='false' aria-label='Menu Colapso'><Link to={HOME}>Home</Link></li>
              <li data-toggle='collapse' data-target='#menu' aria-controls='menu' aria-expanded='false' aria-label='Menu Colapso'><Link to={CURSOS}>Cursos</Link></li>
              <li data-toggle='collapse' data-target='#menu' aria-controls='menu' aria-expanded='false' aria-label='Menu Colapso'><Link to={AGENDA}>Agenda</Link></li>
              <li data-toggle='collapse' data-target='#menu' aria-controls='menu' aria-expanded='false' aria-label='Menu Colapso'><Link to={INSCRICAO}>Inscrição</Link></li>
              <li data-toggle='collapse' data-target='#menu' aria-controls='menu' aria-expanded='false' aria-label='Menu Colapso'><Link to={PAGAMENTO}>Pagamento</Link></li>
              <li data-toggle='collapse' data-target='#menu' aria-controls='menu' aria-expanded='false' aria-label='Menu Colapso'><Link to={AREA_ADMIN}>Consultar Compras</Link></li>
              <li data-toggle='collapse' data-target='#menu' aria-controls='menu' aria-expanded='false' aria-label='Menu Colapso'><Link to={CONTATO}>Contato</Link></li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Header

// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react'
import t from 'prop-types'
import { CategoriaContext } from 'contexts/categorias'
import api from 'services/api'
import ModalDefault from 'utils/modal-default'

const ModalDelCategoria = ({ modal, toggle, categoriaAtual }) => {
  const { listarCategorias } = useContext(CategoriaContext)

  const [categoriaInfo, setCategoriaInfo] = useState({
    nome: ''
  })

  const [error, setError] = useState(false)

  useEffect(() => {
    setError(false)
    const categoria = {
      nome: categoriaAtual.nome
    }
    setCategoriaInfo(categoria)
  }, [categoriaAtual])

  const handleDeletar = (e) => {
    e.preventDefault()

    if (categoriaAtual.cursos.length > 0) {
      setError(true)
    } else {
      api.delete(`/categorias/${categoriaAtual.idCategoria}`)
        .then((response) => {
          listarCategorias()
          toggle()
        })
    }
  }

  return (
    <>
      <ModalDefault modal={modal} toggle={toggle} titulo='Deletar Categoria' btnLabel='Deletar' handleAction={handleDeletar}>
        <p>
          Deseja deletar a categoria {categoriaInfo.nome}?
        </p>

        {error && (
          <div className='alert alert-danger' role='alert'>
            Esta categoria ainda possui cursos, por favor exclua os cursos antes de deletar a categoria.
          </div>
        )}
      </ModalDefault>
    </>
  )
}

ModalDelCategoria.propTypes = {
  modal: t.bool,
  toggle: t.func,
  categoriaAtual: t.object
}

export default ModalDelCategoria

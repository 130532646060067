import React, { createContext, useState, useCallback } from 'react'
import t from 'prop-types'
import { del } from 'idb-keyval'

export const AuthContext = createContext({})

const Auth = ({ children }) => {
  const estadoInicial = {
    user: null,
    isUserLoggedIn: false,
    token: null
  }

  const [userLogin, setUserLogin] = useState(estadoInicial)

  const login = useCallback((infos) => {
    setUserLogin({
      isUserLoggedIn: infos.isUserLoggedIn,
      user: infos.user,
      token: infos.token
    })
  }, [])

  const logout = useCallback(() => {
    del('usuario')
    setUserLogin(estadoInicial)
  }, [estadoInicial])

  return (
    <AuthContext.Provider value={{
      login,
      logout,
      userLogin,
      setUserLogin
    }}
    >
      {children}
    </AuthContext.Provider>
  )
}

Auth.propTypes = {
  children: t.node.isRequired
}

export default Auth

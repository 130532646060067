import formatar from 'formatar-valores'

export const formatTel = (v) => {
  v = v.replace(/\D/g, '')
  v = v.replace(/^(\d{2})(\d)/g, '($1) $2')
  v = v.replace(/(\d)(\d{4})$/, '$1-$2')
  return v
}

export const formatReal = (valor) => {
  valor = formatar.moeda(valor, 2)
  valor = valor + ''
  valor = parseInt(valor.replace(/[\D]+/g, ''))
  valor = valor + ''
  valor = valor.replace(/([0-9]{2})$/g, ',$1')

  if (valor.length > 6) {
    valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2')
  }
  return 'R$ ' + valor
}

export const formatDate = (date) => {
  if (date !== undefined && date !== null) {
    if (!date.toString().includes(' ') && date.toString().includes('-')) {
      const spliteDate = date.split('-')
      date = new Date(Number(spliteDate[0]), Number(spliteDate[1]) - 1, Number(spliteDate[2]))
    }

    const month = date.getMonth()

    switch (month) {
      case 0:
        return `${date.getDate()} de Janeiro de ${date.getFullYear()}`
      case 1:
        return `${date.getDate()} de Fevereiro de ${date.getFullYear()}`
      case 2:
        return `${date.getDate()} de Março de ${date.getFullYear()}`
      case 3:
        return `${date.getDate()} de Abril de ${date.getFullYear()}`
      case 4:
        return `${date.getDate()} de Maio de ${date.getFullYear()}`
      case 5:
        return `${date.getDate()} de Junho de ${date.getFullYear()}`
      case 6:
        return `${date.getDate()} de Julho de ${date.getFullYear()}`
      case 7:
        return `${date.getDate()} de Agosto de ${date.getFullYear()}`
      case 8:
        return `${date.getDate()} de Setembro de ${date.getFullYear()}`
      case 9:
        return `${date.getDate()} de Outubro de ${date.getFullYear()}`
      case 10:
        return `${date.getDate()} de Novembro de ${date.getFullYear()}`
      case 11:
        return `${date.getDate()} de Dezembro de ${date.getFullYear()}`
      default:
        return new Date().toLocaleDateString()
    }
  } else {
    return ''
  }
}

export default (valor) => {
  if (valor !== null && valor !== undefined && valor.length > 0) {
    if (valor.length === 11) {
      return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
    } else if (valor.length === 14) {
      return valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
    }
  } else {
    return valor
  }
}

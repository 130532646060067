// @ts-nocheck
import React, { useContext, Fragment, useState } from 'react'
import t from 'prop-types'
import { useHistory } from 'react-router-dom'

import { CategoriaContext } from 'contexts/categorias'
import { VIEW_CATEGORIA } from 'routes'
import { ModalAddCategoria, ModalEdtCategoria, ModalDelCategoria } from 'pages/ambiente-interno/manter-categoria'

const TabelaCategoria = ({ categorias }) => {
  const history = useHistory()

  const { categoriaAtual, buscarCategoria } = useContext(CategoriaContext)

  const [modalAdd, setModalAdd] = useState(false)
  const [modalEdt, setModalEdt] = useState(false)
  const [modalDel, setModalDel] = useState(false)

  const toggleAdd = () => setModalAdd(!modalAdd)
  const toggleEdt = () => setModalEdt(!modalEdt)
  const toggleDel = () => setModalDel(!modalDel)

  const handleVisualizarCategoria = (e, id) => {
    history.push(VIEW_CATEGORIA.replace(':idCategoria', id))
  }

  const handleEditar = (e, id) => {
    buscarCategoria(id)
    toggleEdt()
  }

  const handleDeletar = (e, id) => {
    buscarCategoria(id)
    toggleDel()
  }

  return (
    <>
      <div className='section-top-border'>
        <div className='row'>
          <div className='col-md-6'>
            <h3 className='mb-30'>Categorias dos Cursos</h3>
          </div>

          <div className='col-md-6 text-right'>
            <button className='genric-btn primary radius' onClick={toggleAdd}>
              Adicionar Categoria
            </button>
            <ModalAddCategoria modal={modalAdd} toggle={toggleAdd} />
          </div>
        </div>
        <table className='table'>
          <thead>
            <tr>
              <th scope='col'>Nome</th>
              <th scope='col'>Descrição</th>
              <th scope='col'>Ações</th>
            </tr>
          </thead>
          <tbody>
            {categorias.map((categoria, index) => (
              <Fragment key={index}>
                <tr key={categoria.idCategoria}>
                  <td>{categoria.nome}</td>
                  <td>{categoria.descricao}</td>
                  <td>
                    <button
                      onClick={(e) => handleVisualizarCategoria(e, categoria.idCategoria)}
                      className='genric-btn info-border radius small ml-10'
                      style={{ padding: '4px', width: '50px' }}
                    >
                      <span
                        className='lnr lnr lnr-eye'
                        style={{ fontSize: '20px', margin: '2px' }}
                      />
                    </button>
                    <button
                      onClick={(e) => handleEditar(e, categoria.idCategoria)}
                      className='genric-btn warning-border radius small ml-10'
                      style={{ padding: '4px', width: '50px' }}
                    >
                      <span
                        className='lnr lnr-pencil'
                        style={{ fontSize: '20px', margin: '2px' }}
                      />
                    </button>
                    <button
                      onClick={(e) => handleDeletar(e, categoria.idCategoria)}
                      className='genric-btn danger-border radius small ml-10'
                      style={{ padding: '4px', width: '50px' }}
                    >
                      <span
                        className='lnr lnr lnr-trash'
                        style={{ fontSize: '20px', margin: '2px' }}
                      />
                    </button>
                  </td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>

      <ModalEdtCategoria modal={modalEdt} toggle={toggleEdt} categoriaAtual={categoriaAtual} />

      <ModalDelCategoria modal={modalDel} toggle={toggleDel} categoriaAtual={categoriaAtual} />
    </>
  )
}

TabelaCategoria.propTypes = {
  categorias: t.array
}

export default TabelaCategoria

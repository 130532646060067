import React, { useContext, useEffect, Fragment, useState } from 'react'
import MensagemListaVazia from 'utils/msg-lista-vazia'
// @ts-ignore
import JsPDF from 'jspdf'
import 'jspdf-autotable'
import { BannerDefault } from 'ui'
import { Link } from 'react-router-dom'
import { HOME, AGENDA, CURSOS } from 'routes'
import { TurmaContext } from 'contexts/turmas'
import { formatDate } from 'utils/mask'

const Agenda = () => {
  const [loading, setLoading] = useState(true)

  // @ts-ignore
  const { listarTurmas, turmas } = useContext(TurmaContext)

  useEffect(() => {
    listarTurmas()
    setLoading(false)
  }, [listarTurmas])

  const generate = () => {
    const filtro = turmas.map((turma) => {
      return {
        nome: turma.nome,
        dataInicio: formatDate(turma.dataInicio),
        dataFim: formatDate(turma.dataFim),
        curso: turma.curso.nome
      }
    })

    const values = filtro.map((elemento) => Object.values(elemento))

    const doc = new JsPDF()
    const finalY = doc.previousAutoTable.finalY || 10
    doc.text('Agenda de Turmas', 14, finalY + 15)
    doc.autoTable({
      startY: finalY + 20,
      head: [['Nome', 'Data de Inicio', 'Data Final', 'Curso']],
      body: values
    })
    doc.save('agenda.pdf')
  }
  return (
    <>
      <BannerDefault>
        <h1 className='text-white'>Agenda</h1>
        <p className='text-white link-nav'>
          <Link to={HOME}>Home</Link>
          <span className='lnr lnr-arrow-right' />
          <Link to={AGENDA}>Agenda</Link>
        </p>
      </BannerDefault>
      <section className='post-content-area single-post-area'>
        <div className='container'>
          <div className='section-top-border'>
            <h3 className='mb-30'>Próximos Cursos</h3>
            {loading ? (
              <div className='spinner-border mt-30' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            ) : turmas.length > 0 ? (
              <>
                <table className='table'>
                  <thead>
                    <tr>
                      <th scope='col'>Nome</th>
                      <th scope='col'>Data de Inicio</th>
                      <th scope='col'>Curso</th>
                    </tr>
                  </thead>
                  <tbody>
                    {turmas.map((turma, index) => (
                      <Fragment key={index}>
                        <tr>
                          <td>{turma.nome}</td>
                          <td>{formatDate(turma.dataInicio)}</td>
                          <td>{turma.curso.nome}</td>
                        </tr>
                      </Fragment>
                    ))}
                  </tbody>
                </table>
                <div className='container'>
                  <div className='row'>
                    <div className='col-sm'>
                      <Link to={CURSOS} className='primary-btn'>
                        Inscrever-se
                      </Link>
                    </div>
                    <div className='col-sm'>
                      <button
                        onClick={generate}
                        className='genric-btn primary radius'
                      >
                        Imprimir Agenda
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <MensagemListaVazia mensagem='Ainda não possuímos Turmas formadas.' />
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default Agenda

import React, { Fragment } from 'react'
import t from 'prop-types'

const TabelaEmenta = ({ ementas, handleEditar, handleDeletar }) => {
  return (
    <>
      <div className='section-top-border'>
        <div className='row'>
          <div className='col-md-6'>
            <h3 className='mb-30'>Ementas</h3>
          </div>
        </div>
        <table className='table'>
          <thead>
            <tr>
              <th scope='col'>Item</th>
              <th scope='col'>Ações</th>
            </tr>
          </thead>
          <tbody>
            {ementas.map((ementa, index) => (
              <Fragment key={index}>
                <tr>
                  <td>{ementa.item}</td>
                  <td>
                    <button
                      onClick={(e) => handleEditar(e, ementa.idEmenta, index)}
                      className='genric-btn warning-border radius small ml-10'
                      style={{ padding: '4px', width: '50px' }}
                    >
                      <span
                        className='lnr lnr-pencil'
                        style={{ fontSize: '20px', margin: '2px' }}
                      />
                    </button>
                    <button
                      onClick={(e) => handleDeletar(e, ementa.idEmenta, index)}
                      className='genric-btn danger-border radius small ml-10'
                      style={{ padding: '4px', width: '50px' }}
                    >
                      <span
                        className='lnr lnr lnr-trash'
                        style={{ fontSize: '20px', margin: '2px' }}
                      />
                    </button>
                  </td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

TabelaEmenta.propTypes = {
  ementas: t.array,
  handleEditar: t.func,
  handleDeletar: t.func
}

export default TabelaEmenta

import React, { useContext, useState, Fragment } from 'react'
import t from 'prop-types'
import { useHistory } from 'react-router-dom'

import { TurmaContext } from 'contexts/turmas'
import { VIEW_TURMA } from 'routes'
import ModalAddTurma from 'pages/ambiente-interno/manter-turma/add-turma'
import ModalEdtTurma from 'pages/ambiente-interno/manter-turma/edt-turma'
import ModalDelTurma from 'pages/ambiente-interno/manter-turma/del-turma'
import { formatDate } from 'utils/mask'

const TabelaTurma = ({ turmas }) => {
  const history = useHistory()

  // @ts-ignore
  const { turmaAtual, buscarTurma } = useContext(TurmaContext)

  const [modalAdd, setModalAdd] = useState(false)
  const [modalEdt, setModalEdt] = useState(false)
  const [modalDel, setModalDel] = useState(false)

  const toggleAdd = () => setModalAdd(!modalAdd)
  const toggleEdt = () => setModalEdt(!modalEdt)
  const toggleDel = () => setModalDel(!modalDel)

  // @ts-ignore
  const handleVisualizarTurma = (e, id) => {
    history.push(VIEW_TURMA.replace(':idTurma', id))
  }

  // @ts-ignore
  const handleEditar = (e, id) => {
    buscarTurma(id)
    toggleEdt()
  }

  // @ts-ignore
  const handleDeletar = (e, id) => {
    buscarTurma(id)
    toggleDel()
  }

  return (
    <>
      <div className='section-top-border'>
        <div className='row'>
          <div className='col-md-6'>
            <h3 className='mb-30'>Agenda das Turmas</h3>
          </div>

          <div className='col-md-6 text-right'>
            <button className='genric-btn primary radius' onClick={toggleAdd}>
              Adicionar Turma na Agenda
            </button>
            <ModalAddTurma modal={modalAdd} toggle={toggleAdd} />
          </div>
        </div>
        <table className='table'>
          <thead>
            <tr>
              <th scope='col'>Nome</th>
              <th scope='col'>Data de Inicio</th>
              <th scope='col'>Curso</th>
              <th scope='col'>Ações</th>
            </tr>
          </thead>
          <tbody>
            {turmas.map((turma, index) => (
              <Fragment key={index}>
                <tr>
                  <td>{turma.nome}</td>
                  <td>{formatDate(turma.dataInicio)}</td>
                  <td>{turma.curso !== undefined ? turma.curso.nome : ''}</td>
                  <td>
                    <button
                      onClick={(e) => handleVisualizarTurma(e, turma.idTurma)}
                      className='genric-btn info-border radius small ml-10'
                      style={{ padding: '4px', width: '50px' }}
                    >
                      <span
                        className='lnr lnr lnr-eye'
                        style={{ fontSize: '20px', margin: '2px' }}
                      />
                    </button>
                    <button
                      onClick={(e) => handleEditar(e, turma.idTurma)}
                      className='genric-btn warning-border radius small ml-10'
                      style={{ padding: '4px', width: '50px' }}
                    >
                      <span
                        className='lnr lnr-pencil'
                        style={{ fontSize: '20px', margin: '2px' }}
                      />
                    </button>
                    <button
                      onClick={(e) => handleDeletar(e, turma.idTurma)}
                      className='genric-btn danger-border radius small ml-10'
                      style={{ padding: '4px', width: '50px' }}
                    >
                      <span
                        className='lnr lnr lnr-trash'
                        style={{ fontSize: '20px', margin: '2px' }}
                      />
                    </button>
                  </td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>

      <ModalEdtTurma modal={modalEdt} toggle={toggleEdt} turmaAtual={turmaAtual} />

      <ModalDelTurma modal={modalDel} toggle={toggleDel} turmaAtual={turmaAtual} />
    </>
  )
}

TabelaTurma.propTypes = {
  turmas: t.array
}

export default TabelaTurma

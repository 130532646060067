import React, { useEffect, useContext } from 'react'
import { BannerDefault } from 'ui'
import { Link } from 'react-router-dom'
import { HOME, AMBIENTE_INTERNO, MANTER_TURMA } from 'routes'

import { TurmaContext } from 'contexts/turmas'

import TabelaTurma from 'pages/ambiente-interno/manter-turma/tabela-turma'

const ManterTurma = () => {
  // @ts-ignore
  const { turmas, listarTurmas } = useContext(TurmaContext)

  useEffect(() => {
    listarTurmas()
  }, [listarTurmas])

  return (
    <>
      <BannerDefault>
        <h1 className='text-white'>
          Ambiente Interno
        </h1>
        <p className='text-white link-nav'>
          <Link to={HOME}>Home</Link>
          <span className='lnr lnr-arrow-right' />
          <Link to={AMBIENTE_INTERNO}>Ambiente Interno</Link>
          <span className='lnr lnr-arrow-right' />
          <Link to={MANTER_TURMA}>Manter Agenda das Turmas</Link>
        </p>
      </BannerDefault>

      <section className='post-content-area single-post-area'>
        <div className='container'>
          <TabelaTurma
            turmas={turmas}
          />
        </div>
      </section>
    </>
  )
}

export default ManterTurma

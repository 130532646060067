// @ts-nocheck
import React, { useState } from 'react'
import { BannerDefault } from 'ui'
import { Link } from 'react-router-dom'
import { HOME, AREA_ADMIN } from 'routes'
import api from 'services/api'
import { formatReal } from 'utils/mask'

const AreaAdmin = () => {
  const [error, setError] = useState(false)
  const [load, setLoad] = useState(false)
  const [codTransacao, setCodTransacao] = useState('')
  const [transacao, setTransacao] = useState('')

  const consultarTransacao = () => {
    if (codTransacao !== '' && (codTransacao.length === 32 || codTransacao.length === 36)) {
      setError(false)
      setLoad(true)
      setTransacao('')
      api.get(`/transacoes/${codTransacao}&&contato@parallel.com.br&&a519c6f4-c874-437b-888a-53197c2f6176309a3d5e492f90251d3da515bb888f5a899c-95d7-4e62-bffe-31ce9e426b8b`)
        .then((response) => {
          setTransacao({
            code: response.data.code,
            date: response.data.date,
            grossAmount: response.data.grossAmount,
            items: response.data.items,
            paymentMethod: response.data.paymentMethod,
            paymentLink: response.data.paymentLink,
            sender: response.data.sender,
            status: response.data.status
          })
          setLoad(false)
        })
    } else {
      setError(true)
    }
  }

  const handleFocus = (e) => {
    e.target.placeholder = ''
  }

  const handleBlur = (e, placeholder) => {
    e.target.placeholder = placeholder
  }

  return (
    <>
      <BannerDefault>
        <h1 className='text-white'>
          Consultar Compras
        </h1>
        <p className='text-white link-nav'>
          <Link to={HOME}>Home</Link>
          <span className='lnr lnr-arrow-right' />
          <Link to={AREA_ADMIN}>Consultar Compras</Link>
        </p>
      </BannerDefault>

      <section className='post-content-area single-post-area'>
        <div className='container'>
          <div className='comment-form'>
            <h4>Consultar Detalhes da Compra</h4>
            <div className='form-group form-inline'>
              <div className='form-group col-lg-12 col-md-12'>
                <label htmlFor='cod'>Informe o código da transação disponibilizado na hora da compra ou presente no email enviado para a conclusão da compra</label>
                <input
                  type='text'
                  className='form-control mt-10'
                  id='cod'
                  placeholder='Digite o Código da Transação'
                  required
                  onFocus={handleFocus}
                  onBlur={(e) => handleBlur(e, 'Digite o Código da Transação')}
                  onChange={(e) => {
                    setCodTransacao(e.target.value)
                  }}
                />
              </div>
            </div>

            <div className='mt-30 mb-30'>
              <button onClick={consultarTransacao} className='genric-btn primary radius'>Consultar</button>
            </div>

            {error && (
              <div className='alert alert-warning' role='alert'>
                Informe um código de transação válido.
              </div>
            )}

            {load && (
              <div className='spinner-border' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            )}

            {transacao !== '' && (
              <>
                <div className='comment-form'>
                  <h2 className='mb-20'>Dados da Compra</h2>

                  <div className='form-group form-inline'>
                    <div className='form-group col-lg-2 col-md-2'>
                      <h5 className=''>Código da Transação</h5>
                    </div>
                    <div className='form-group col-lg-10 col-md-10'>
                      <p>{transacao.code}</p>
                    </div>
                  </div>

                  <div className='form-group form-inline'>
                    <div className='form-group col-lg-2 col-md-2'>
                      <h5 className=''>Status da Compra</h5>
                    </div>
                    <div className='form-group col-lg-10 col-md-10'>
                      <p>{transacao.status === '1' ? 'Aguardando pagamento' : transacao.status === '2' ? 'Em análise'
                        : transacao.status === '3' ? 'Paga' : transacao.status === '4' ? 'Disponível'
                          : transacao.status === '5' ? 'Em disputa' : transacao.status === '6' ? 'Devolvida'
                            : transacao.status === '7' ? 'Cancelada' : 'Não foi possível identificar o status da compra'}
                      </p>
                    </div>
                  </div>

                  <div className='form-group form-inline'>
                    <div className='form-group col-lg-2 col-md-2'>
                      <h5 className=''>Data da Compra</h5>
                    </div>
                    <div className='form-group col-lg-10 col-md-10'>
                      <p>{new Date(transacao.date).toLocaleDateString()}</p>
                    </div>
                  </div>

                  <div className='form-group form-inline'>
                    <div className='form-group col-lg-2 col-md-2'>
                      <h5 className=''>Curso Adquirido</h5>
                    </div>
                    <div className='form-group col-lg-10 col-md-10'>
                      <p>{transacao.items.item.description}</p>
                    </div>
                  </div>

                  <div className='form-group form-inline'>
                    <div className='form-group col-lg-2 col-md-2'>
                      <h5 className=''>Valor do Curso</h5>
                    </div>
                    <div className='form-group col-lg-10 col-md-10'>
                      <p>{formatReal(transacao.grossAmount)}</p>
                    </div>
                  </div>

                  {transacao.status === '1' && transacao.paymentMethod.code === '202' && (
                    <>
                      <h5>Caso ainda não tenha feito o pagamento, clique no botão abaixo para acessar o boleto.</h5>
                      <div className='mt-30 mb-30'>
                        <a href={transacao.paymentLink}><button className='genric-btn primary radius'>Boleto de Pagemento</button></a>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default AreaAdmin

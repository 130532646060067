export const HOME = '/'
export const AUTENTICAR = '/autenticar'
export const LOGOUT = '/logout'
export const CURSOS = '/cursos'
export const CONTATO = '/contato'
export const INSCRICAO = '/inscricao/:idCurso'
export const AGENDA = '/agenda'
export const LOGIN = '/login'
export const AMBIENTE_INTERNO = '/ambiente-interno'
export const MANTER_CURSO = '/manter-curso'
export const VIEW_CURSO = '/view-curso/:idCurso'
export const MANTER_CATEGORIA = '/manter-categoria'
export const VIEW_CATEGORIA = '/view-categoria/:idCategoria'
export const MANTER_TURMA = '/manter-turma'
export const VIEW_TURMA = '/view-turma/:idTurma'
export const AREA_ADMIN = '/view-detalhes-compras'
export const GERENCIAR_CERTIFICADOS = '/gerenciar-certificados'
export const GERAR_CERTIFICADO = '/gerar-certificado'
export const ENVIAR_CERTIFICADO = '/enviar-certificado'
export const PAGAMENTO = '/pagamento'

import React, { useEffect, useContext } from 'react'
import { BannerDefault } from 'ui'
import { HOME, MANTER_CURSO, VIEW_CURSO } from 'routes'
import { Link, useParams } from 'react-router-dom'
import { CursoContext } from 'contexts/cursos'
import { formatReal } from 'utils/mask'

const ViewCurso = () => {
  // @ts-ignore
  const { idCurso } = useParams()
  // @ts-ignore
  const { buscarCurso, cursoAtual } = useContext(CursoContext)

  useEffect(() => {
    buscarCurso(idCurso)
  }, [buscarCurso, idCurso])

  return (
    <>
      <BannerDefault>
        <h1 className='text-white'>
          Ambiente Interno - Visualizar Curso
        </h1>
        <p className='text-white link-nav'>
          <Link to={HOME}>Home</Link>
          <span className='lnr lnr-arrow-right' />
          <Link to={MANTER_CURSO}>Manter Curso</Link>
          <span className='lnr lnr-arrow-right' />
          <Link to={VIEW_CURSO}>Visualizar Curso</Link>
        </p>
      </BannerDefault>

      <section className='post-content-area single-post-area'>
        <div className='container'>

          <div className='title text-center'>
            <h1 className='mb-40'>Visualizar Curso</h1>
          </div>

          <h3 className='mb-30'>{cursoAtual.nome}</h3>

          <h4 className='mb-10'>Descrição</h4>
          <p className='mb-30'>{cursoAtual.descricao}</p>

          <h4 className='mb-10'>Preço do Curso</h4>
          <p className='mb-30'>{cursoAtual.preco !== undefined ? formatReal(cursoAtual.preco) : ''}</p>

          <h4 className='mb-10'>Categoria</h4>
          <p className='mb-30'>{cursoAtual.categoria !== undefined ? cursoAtual.categoria.nome : ''}</p>

          <h4 className='mb-10'>Publico Alvo</h4>
          <p className='mb-30'>{cursoAtual.publicoAlvo}</p>

          <h4 className='mb-10'>Carga Horaria</h4>
          <p className='mb-30'>{cursoAtual.cargaHoraria}</p>

          <h4 className='mb-10'>Objetivo</h4>
          <p className='mb-30'>{cursoAtual.objetivo}</p>

          <h4 className='mb-10'>Metodologia</h4>
          <p className='mb-30'>{cursoAtual.metodologia}</p>

          <h4 className='mb-10'>Programa do curso</h4>
          <ol className='ordered-list'>
            {cursoAtual.ementas !== undefined ? cursoAtual.ementas.map((item) => {
              return (
                <li key={item.item}><span>{item.item}</span></li>
              )
            }
            ) : ''}
          </ol>
        </div>
      </section>
    </>
  )
}

export default ViewCurso

import React from 'react'
import t from 'prop-types'

const MensagemListaVazia = ({ mensagem }) => {
  return (
    <section>
      <div className='container'>
        <p className='mt-30 mb-30' style={{ height: '80vh' }}>{mensagem}</p>
      </div>
    </section>
  )
}

MensagemListaVazia.propTypes = {
  mensagem: t.string
}

export default MensagemListaVazia

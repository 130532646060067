import React, { useEffect, useContext } from 'react'
import { BannerDefault } from 'ui'
import { HOME, MANTER_CATEGORIA, VIEW_CATEGORIA } from 'routes'
import { Link, useParams } from 'react-router-dom'
import { CategoriaContext } from 'contexts/categorias'

const ViewCategoria = () => {
  // @ts-ignore
  const { idCategoria } = useParams()
  // @ts-ignore
  const { buscarCategoria, categoriaAtual } = useContext(CategoriaContext)

  useEffect(() => {
    buscarCategoria(idCategoria)
  }, [buscarCategoria, idCategoria])
  return (
    <>
      <BannerDefault>
        <h1 className='text-white'>
          Ambiente Interno - Visualizar Categoria
        </h1>
        <p className='text-white link-nav'>
          <Link to={HOME}>Home</Link>
          <span className='lnr lnr-arrow-right' />
          <Link to={MANTER_CATEGORIA}>Manter Categoria</Link>
          <span className='lnr lnr-arrow-right' />
          <Link to={VIEW_CATEGORIA}>Visualizar Categoria</Link>
        </p>
      </BannerDefault>

      <section className='post-content-area single-post-area'>
        <div className='container'>

          <div className='title text-center'>
            <h1 className='mb-40'>Visualizar Categoria</h1>
          </div>

          <h3 className='mb-30'>{categoriaAtual.nome}</h3>

          <h4 className='mb-10'>Descrição</h4>
          <p className='mb-30'>{categoriaAtual.descricao}</p>
        </div>
      </section>
    </>
  )
}

export default ViewCategoria

import React from 'react'
import ListaCursos from './lista-cursos'
import BannerCurso from './curso-banner'

const Cursos = () => {
  return (
    <>
      <BannerCurso />

      <ListaCursos />
    </>
  )
}

export default Cursos

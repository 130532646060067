import React, { useState } from 'react'
import t from 'prop-types'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const ModalAddEmenta = ({ handleAddEmenta, openAddModal, toggleAddModal }) => {
  const [ementaInfo, setEmentaInfo] = useState({ idEmenta: 0, item: '' })

  const addEmenta = () => {
    handleAddEmenta(ementaInfo)

    setEmentaInfo((prevState) => {
      return { idEmenta: prevState.idEmenta++, item: '' }
    })
  }

  const handleFocus = (e) => {
    e.target.placeholder = ''
  }

  const handleBlur = (e, placeholder) => {
    e.target.placeholder = placeholder
  }

  return (
    <Modal isOpen={openAddModal} toggle={toggleAddModal}>
      <ModalHeader>Adicionar Ementas ao Curso</ModalHeader>
      <ModalBody>
        <div className='form-group form-inline'>
          <div className='form-group'>
            <input
              type='text'
              className='form-control'
              style={{ width: '400px' }}
              id='item-add'
              placeholder='Digite o item da ementa'
              value={ementaInfo.item}
              required
              onFocus={handleFocus}
              onBlur={(e) => handleBlur(e, 'Digite o item da ementa')}
              onChange={(e) => {
                const val = e.target.value
                setEmentaInfo((prevState) => {
                  return { ...prevState, item: val }
                })
              }}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type='button'
          className='genric-btn primary radius'
          onClick={addEmenta}
        >
          Adicionar
        </button>
        <button
          type='button'
          className='genric-btn primary-border radius'
          onClick={toggleAddModal}
        >
          Cancelar
        </button>
      </ModalFooter>
    </Modal>
  )
}

ModalAddEmenta.propTypes = {
  handleAddEmenta: t.func,
  openAddModal: t.bool,
  toggleAddModal: t.func
}

export default ModalAddEmenta

/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
// @ts-nocheck
/* eslint-disable import/no-duplicates */
import React, { useState } from 'react'
import MapaContainer from 'pages/contato/mapa-container'

import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Alert'

import api from 'services/api'

const AreaEndereco = () => {
  const [contato, setContato] = useState({
    nome: '',
    email: '',
    replyTo: '',
    assunto: '',
    mensagem: ''
  })
  const handleFocus = (e) => {
    e.target.placeholder = ''
  }

  const handleBlur = (e, placeholder) => {
    e.target.placeholder = placeholder
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const body = {
      nome: contato.nome,
      email: 'contato@parallel.com.br',
      replyTo: contato.email,
      assunto: contato.assunto,
      mensagem: contato.mensagem
    }
    api.post('/enviar_email', body)
      .then((response) => {
        setContato({})
      })
    setShow(false)
    e.target.reset()
  }

  const [show, setShow] = useState(true)

  return (
    <section className='contact-page-area section-gap'>
      <div className='container'>
        <div className='row'>
          <MapaContainer />
          <div className='col-lg-4 d-flex flex-column address-wrap'>
            <div className='single-contact-address d-flex flex-row'>
              <div className='icon'>
                <span className='lnr lnr-home' />
              </div>
              <div className='contact-details'>
                <h5>Endereço</h5>
                <p>
                  Avenida Antonio Carlos Magalhães, número 2487, Edifício Fernandez Plaza, 19o andar, salas 1907 e 1908, Parque Bela Vista, CEP: 40.280-000
                </p>
              </div>
            </div>
            <div className='single-contact-address d-flex flex-row'>
              <div className='icon'>
                <span className='lnr lnr-phone-handset' />
              </div>
              <div className='contact-details' style={{ marginRight: '25px' }}>
                <h5>Telefone</h5>
                <p>(71) 3451-6944</p>
              </div>
              <div className='contact-details'>
                <h5>Whatsapp</h5>
                <p>(71) 9 8555-3564</p>
              </div>
            </div>
            <div className='single-contact-address d-flex flex-row'>
              <div className='icon'>
                <span className='lnr lnr-envelope' />
              </div>
              <div className='contact-details'>
                <h5>E-mail</h5>
                <p>contato@parallel.com.br</p>
              </div>
            </div>
          </div>
          <div className='col-lg-8'>
            <form onSubmit={handleSubmit} className='form-area contact-form text-right' id='myForm1'>
              <div className='row'>
                <div className='col-lg-6 form-group'>
                  <input
                    name='nome'
                    placeholder='Digite o seu Nome'
                    onFocus={handleFocus}
                    onBlur={(e) => handleBlur(e, 'Digite o seu Nome')}
                    className='common-input mb-20 form-control'
                    required
                    type='text'
                    onChange={(e) => {
                      const val = e.target.value
                      setContato(prevState => {
                        return { ...prevState, nome: val }
                      })
                    }}
                  />

                  <input
                    name='email'
                    placeholder='Digite o seu Email'
                    pattern='[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$'
                    onFocus={handleFocus}
                    onBlur={(e) => handleBlur(e, 'Digite o seu Email')}
                    className='common-input mb-20 form-control'
                    required
                    type='email'
                    onChange={(e) => {
                      const val = e.target.value
                      setContato(prevState => {
                        return { ...prevState, email: val }
                      })
                    }}
                  />

                  <input
                    name='assunto'
                    placeholder='Digite um Assunto'
                    onFocus={handleFocus}
                    onBlur={(e) => handleBlur(e, 'Digite um Assunto')}
                    className='common-input mb-20 form-control'
                    required
                    type='text'
                    onChange={(e) => {
                      const val = e.target.value
                      setContato(prevState => {
                        return { ...prevState, assunto: val }
                      })
                    }}
                  />
                </div>
                <div className='col-lg-6 form-group'>
                  <textarea
                    className='common-textarea form-control'
                    name='mensagem'
                    placeholder='Digite uma Mensagem'
                    onFocus={handleFocus}
                    onBlur={(e) => handleBlur(e, 'Digite uma Mensagem')}
                    onChange={(e) => {
                      const val = e.target.value
                      setContato(prevState => {
                        return { ...prevState, mensagem: val }
                      })
                    }}
                  />
                </div>
                <div className='col-lg-12'>
                  <div className='alert-msg' style={{ textAlign: 'left' }} />
                  {!show && <Alert variant='success'>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      position: 'relative'
                    }}
                    >
                      Obrigado pelo contato!
                      <Button onClick={() => setShow(true)} style={{ margin: '0', padding: '0', right: '0', position: 'absolute', cursor: 'pointer' }}>
                        &times;
                      </Button>
                    </div>
                  </Alert>}
                  <button type='submit' className='genric-btn primary' style={{ float: 'right' }}>Enviar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AreaEndereco

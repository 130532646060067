import React, { createContext, useState, useCallback } from 'react'
import t from 'prop-types'
import api from 'services/api'

export const CategoriaContext = createContext({})

const Categoria = ({ children }) => {
  const [categorias, setCategorias] = useState([])
  const [categoriaAtual, setCategoriaAtual] = useState({})

  const listarCategorias = useCallback(() => {
    api.get('/categorias')
      .then((response) => {
        setCategorias(response.data)
      })
  }, [])

  const buscarCategoria = useCallback((idCategoria) => {
    api.get(`/categorias/${idCategoria}`)
      .then((response) => {
        setCategoriaAtual(response.data)
      })
  }, [])

  return (
    <CategoriaContext.Provider value={{
      categoriaAtual,
      setCategoriaAtual,
      categorias,
      setCategorias,
      listarCategorias,
      buscarCategoria
    }}
    >
      {children}
    </CategoriaContext.Provider>
  )
}

Categoria.propTypes = {
  children: t.node.isRequired
}

export default Categoria

// @ts-nocheck
import React, { useContext, useEffect, Fragment, useState } from 'react'
import { CursoContext } from 'contexts/cursos'
import { CategoriaContext } from 'contexts/categorias'
import { useHistory } from 'react-router-dom'
import { INSCRICAO } from 'routes'
import MensagemListaVazia from 'utils/msg-lista-vazia'
import { formatReal } from 'utils/mask'
import { Card, Button } from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion'

const ListaCursos = () => {
  const { listarCursos, cursos } = useContext(CursoContext)
  const { listarCategorias, categorias } = useContext(CategoriaContext)
  const [loading, setLoading] = useState(true)

  const history = useHistory()

  useEffect(() => {
    listarCursos()
    listarCategorias()
    setLoading(false)
  }, [listarCursos, listarCategorias])

  const handleClickInscricao = (curso, e) => {
    history.push(INSCRICAO.replace(':idCurso', curso.idCurso))
  }

  return (
    <>
      <section>
        <div className='container py-5'>
          {loading ? (
            <div className='spinner-border mt-30' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          ) : categorias.length > 0 ? (
            categorias.map((categoria, index) => (
              <Fragment key={index}>
                <h3 style={{ color: 'rgb(120, 159, 254)', fontSize: '1.3rem' }}>
                  {categoria.nome.toUpperCase()}
                </h3>

                <div className='row'>
                  <div className='col-lg-8'>
                    <Accordion className='py-2'>
                      {cursos
                        .filter(
                          (item) => item.categoriaId === categoria.idCategoria
                        )
                        .map((curso, index) => (
                          <Fragment key={index}>
                            <Card style={{ backgroundColor: 'transparent' }}>
                              <Card.Header
                                style={{
                                  backgroundColor: 'transparent',
                                  display: 'flex',
                                  justifyContent: 'space-between'
                                }}
                              >
                                <Accordion.Toggle
                                  as={Button}
                                  variant='link'
                                  eventKey={index + 1}
                                >
                                  <p
                                    style={{ color: '#000', fontWeight: 500 }}
                                    className='m-0'
                                  >
                                    {curso.nome}
                                  </p>
                                </Accordion.Toggle>
                                <button
                                  onClick={(e) =>
                                    handleClickInscricao(curso, e)}
                                  type='button'
                                  className='genric-btn info-border circle small'
                                  data-toggle='collapse'
                                  data-target='#'
                                >
                                  Inscreva-se
                                </button>
                              </Card.Header>
                              <Accordion.Collapse eventKey={index + 1}>
                                <Card.Body>
                                  <dd
                                    className='collapse show'
                                    id='collapseOne'
                                    aria-labelledby='headingOne'
                                    data-parent='#accordionExample'
                                  >
                                    <h5 className='mb-10'>Descrição</h5>
                                    <p>{curso.descricao}</p>

                                    <h5 className='mb-10'>Preço do Curso</h5>
                                    <p>
                                      {curso.preco !== null &&
                                      curso.preco !== undefined
                                        ? formatReal(curso.preco)
                                        : ''}
                                    </p>

                                    <h5 className='mb-10'>Público-alvo</h5>
                                    <p>{curso.publicoAlvo}</p>

                                    <h5 className='mb-10'>Carga Horária</h5>
                                    <p>{curso.cargaHoraria}</p>

                                    <h5 className='mb-10'>Objetivo</h5>
                                    <p>{curso.objetivo}</p>

                                    <h5 className='mb-10'>Modalidade</h5>
                                    <p>{curso.modalidade}</p>

                                    <h5 className='mb-10'>Metodologia</h5>
                                    <p>{curso.metodologia}</p>

                                    <h5 className='mb-10'>Programa do curso</h5>
                                    <ol className='ordered-list'>
                                      {curso.ementas !== undefined
                                        ? curso.ementas.map((item, index) => {
                                          return (
                                            <li key={index}>
                                              <span>{item.item}</span>
                                            </li>
                                          )
                                        })
                                        : ''}
                                    </ol>
                                  </dd>
                                </Card.Body>
                              </Accordion.Collapse>
                            </Card>
                          </Fragment>
                        ))}
                    </Accordion>
                  </div>
                </div>
              </Fragment>
            ))
          ) : (
            <MensagemListaVazia mensagem='Ainda não possuímos Cursos cadastrados.' />
          )}
        </div>
      </section>
    </>
  )
}

export default ListaCursos

import React from 'react'
import { Link } from 'react-router-dom'
import { BannerDefault } from 'ui'

const BannerContato = () => {
  return (
    <BannerDefault>
      <h1 className='text-white'>
        Contato
      </h1>
      <p className='text-white link-nav'>
        <Link to='/'>Home</Link>
        <span className='lnr lnr-arrow-right' />
        <Link to='/contato'>Contato</Link>
      </p>
    </BannerDefault>
  )
}

export default BannerContato

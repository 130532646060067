import React, { useState, useContext, useEffect } from 'react'
import { BannerDefault } from 'ui'
import { Link } from 'react-router-dom'
import { HOME, AMBIENTE_INTERNO, GERENCIAR_CERTIFICADOS, ENVIAR_CERTIFICADO } from 'routes'
import { TurmaContext } from 'contexts/turmas'
import api from 'services/api'

const EnviarCertificado = () => {
  // @ts-ignore
  const { turmas, listarTurmas, buscarTurma, turmaAtual } = useContext(TurmaContext)

  const [alert, setAlert] = useState(false)
  const [load, setLoad] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    listarTurmas()
  }, [listarTurmas])

  const [infoEnviarCertificado, setEnviarCertificado] = useState({
    arquivo: {},
    email: '',
    nome: '',
    turma: ''
  })

  const enviarCertificado = (e) => {
    e.preventDefault()

    if (turmaAtual.curso === undefined || infoEnviarCertificado.arquivo === undefined) {
      setError(true)
    } else {
      setError(false)
      e.target.reset()

      setAlert(false)
      setLoad(true)
      const formData = new FormData()
      // @ts-ignore
      formData.append('file', infoEnviarCertificado.arquivo)

      api.post(`/enviar_certificado/${infoEnviarCertificado.email}&&${turmaAtual.curso.nome}&&${infoEnviarCertificado.nome}`, formData)
        // @ts-ignore
        .then((response) => {
          setLoad(false)
          setAlert(true)
        })
    }
  }

  const handleFocus = (e) => {
    e.target.placeholder = ''
  }

  const handleBlur = (e, placeholder) => {
    e.target.placeholder = placeholder
  }

  return (
    <>
      <BannerDefault>
        <h1 className='text-white'>
          Ambiente Interno
        </h1>
        <p className='text-white link-nav'>
          <Link to={HOME}>Home</Link>
          <span className='lnr lnr-arrow-right' />
          <Link to={AMBIENTE_INTERNO}>Ambiente Interno</Link>
          <span className='lnr lnr-arrow-right' />
          <Link to={GERENCIAR_CERTIFICADOS}>Certificados de Participação</Link>
          <span className='lnr lnr-arrow-right' />
          <Link to={ENVIAR_CERTIFICADO}>Enviar Certificados</Link>
        </p>
      </BannerDefault>

      <section className='post-content-area single-post-area'>
        <div className='container'>
          <div className='comment-form'>
            <form onSubmit={enviarCertificado}>
              <h4>Enviar Certificado de Participação por Email</h4>
              <div className='form-group form-inline'>
                <div className='form-group col-lg-6 col-md-12 name'>
                  <input
                    type='text'
                    className='form-control'
                    id='name'
                    placeholder='Nome Completo do Aluno'
                    required
                    onFocus={handleFocus}
                    onBlur={(e) => handleBlur(e, 'Nome Completo do Aluno')}
                    onChange={(e) => {
                      const val = e.target.value
                      setEnviarCertificado(prevState => {
                        return { ...prevState, nome: val }
                      })
                    }}
                  />
                </div>

                <div className='form-group col-lg-6 col-md-12 email'>
                  <input
                    type='email'
                    className='form-control'
                    id='email'
                    placeholder='Email do Aluno'
                    required
                    onFocus={handleFocus}
                    onBlur={(e) => handleBlur(e, 'Email do Aluno')}
                    onChange={(e) => {
                      const val = e.target.value
                      setEnviarCertificado(prevState => {
                        return { ...prevState, email: val }
                      })
                    }}
                  />
                </div>
              </div>

              <div className='form-group form-inline'>
                <div className='form-group col-lg-6 col-md-12 email'>
                  <div className='input-group-icon mt-10'>
                    <div className='single-element-widget'>
                      <h6 className='mb-30'>Turmas</h6>
                      <div className='default-select' id='default-select'>
                        <select
                          value={infoEnviarCertificado.turma}
                          required
                          onChange={(e) => {
                            const val = e.target.value
                            buscarTurma(val)
                            setEnviarCertificado(prevState => {
                              return { ...prevState, turma: val }
                            })
                          }}
                        >
                          <option key='selecionar' value='selecionar'>Selecione uma Turma</option>
                          {turmas.map((turma, index) => (
                            <option key={index} value={turma.idTurma}>{turma.nome}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='form-group form-inline'>
                <div className='form-group col-lg-6 col-md-12 email'>
                  <input
                    type='file'
                    id='file-input'
                    onChange={(e) => {
                      const val = e.target.files[0]
                      setEnviarCertificado(prevState => {
                        return { ...prevState, arquivo: val }
                      })
                    }}
                  />
                </div>
              </div>

              <div className='mt-30 mb-30'>
                <button type='submit' className='genric-btn primary radius'>Enviar Certificado</button>
              </div>

              {load && (
                <div className='spinner-border' role='status'>
                  <span className='sr-only'>Loading...</span>
                </div>
              )}

              {error && (
                <div className='alert alert-warning' role='alert'>
                  Por favor, preencha todos os campos.
                </div>
              )}

              {alert && (
                <div className='alert alert-success' role='alert'>
                  O certificado foi enviado com Sucesso!
                </div>
              )}
            </form>
          </div>
        </div>
      </section>
    </>
  )
}

export default EnviarCertificado

import React from 'react'
import BannerContato from './banner'
import AreaEndereco from './area-endereco'

const Contato = () => {
  return (
    <>
      <BannerContato />

      <AreaEndereco />
    </>
  )
}

export default Contato

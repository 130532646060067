// @ts-nocheck
import React, { useState, useContext, useEffect } from 'react'
import JsPDF from 'jspdf'
import { BannerDefault } from 'ui'
import { Link } from 'react-router-dom'
import { HOME, AMBIENTE_INTERNO, GERENCIAR_CERTIFICADOS, GERAR_CERTIFICADO } from 'routes'
import { imgCertificado } from 'utils/img-certificado'
import { formatDate } from 'utils/mask'
import { TurmaContext } from 'contexts/turmas'
import 'jspdf-autotable'
import { CursoContext } from 'contexts/cursos'

const GerarCertificado = () => {
  const { turmas, listarTurmas, buscarTurma, turmaAtual } = useContext(TurmaContext)
  const { listarCursos } = useContext(CursoContext)

  const [error, setError] = useState(false)

  useEffect(() => {
    listarCursos()
    listarTurmas()
  }, [listarTurmas, listarCursos])

  const [infoGerarCertificado, setGerarCertificado] = useState({})

  const gerarCertificado = (e) => {
    e.preventDefault()

    if (infoGerarCertificado.nome === '' || turmaAtual.curso === undefined || turmaAtual.curso === null) {
      setError(true)
    } else {
      setError(false)
      e.target.reset()

      const imgData = imgCertificado
      const text = `A Parallel Consulting & Training certifica que ${infoGerarCertificado.nome}, concluiu o curso de ${turmaAtual.curso.nome}, no período de ${formatDate(turmaAtual.dataInicio)} a ${formatDate(turmaAtual.dataFim)}, com carga horária de ${turmaAtual.curso.cargaHoraria}.`

      const doc = new JsPDF('l', 'mm', 'a4')

      doc.setFontSize(36)
      doc.addImage(imgData, 'JPEG', 0, 0, 297, 210)
      doc.setFont('helvetica')
      doc.setTextColor(42, 71, 114)
      doc.setFontType('bold')
      doc.text(110, 75, 'CERTIFICADO')
      doc.setFontSize(14)
      doc.setTextColor(0, 0, 0)
      doc.setFontType('normal')
      doc.text(70, 95, text, {
        align: 'justify',
        maxWidth: 170
      })
      doc.text(100, 170, `Salvador, Bahia, ${formatDate(new Date())},`)

      doc.addPage()

      var rows = []
      var col = ['Ementas']

      turmaAtual.curso.ementas.forEach(element => {
        var temp = [element.item]
        rows.push(temp)
      })

      // const conteudo = `Conteudo Programático: ${turmaAtual.curso.ementas}`
      doc.autoTable(col, rows, { startY: 25 })
      // doc.text(35, 25, conteudo)
      doc.text(20, 20, `Salvador, Bahia, ${formatDate(new Date())},`)
      // doc.text(50, 95, conteudo, {
      //   align: 'justify',
      //   maxWidth: 170
      // })
      doc.save(`certificado-${infoGerarCertificado.nome.split(' ')[0]}.pdf`)
    }
  }

  const handleFocus = (e) => {
    e.target.placeholder = ''
  }

  const handleBlur = (e, placeholder) => {
    e.target.placeholder = placeholder
  }

  return (
    <>
      <BannerDefault>
        <h1 className='text-white'>
          Ambiente Interno
        </h1>
        <p className='text-white link-nav'>
          <Link to={HOME}>Home</Link>
          <span className='lnr lnr-arrow-right' />
          <Link to={AMBIENTE_INTERNO}>Ambiente Interno</Link>
          <span className='lnr lnr-arrow-right' />
          <Link to={GERENCIAR_CERTIFICADOS}>Certificados de Participação</Link>
          <span className='lnr lnr-arrow-right' />
          <Link to={GERAR_CERTIFICADO}>Gerar Certificados</Link>
        </p>
      </BannerDefault>

      <section className='post-content-area single-post-area'>
        <div className='container'>
          <div className='comment-form'>
            <form onSubmit={gerarCertificado}>
              <h4>Geração de Certificado de Participação</h4>
              <div className='form-group form-inline'>
                <div className='form-group col-lg-6 col-md-12 name'>
                  <input
                    type='text'
                    className='form-control'
                    id='name'
                    placeholder='Nome Completo do Aluno'
                    required
                    onFocus={handleFocus}
                    onBlur={(e) => handleBlur(e, 'Nome Completo do Aluno')}
                    onChange={(e) => {
                      const val = e.target.value
                      setGerarCertificado(prevState => {
                        return { ...prevState, nome: val }
                      })
                    }}
                  />
                </div>
              </div>

              <div className='form-group form-inline'>
                <div className='form-group col-lg-6 col-md-12 email'>
                  <div className='input-group-icon mt-10'>
                    <div className='single-element-widget'>
                      <h6 className='mb-30'>Turmas</h6>
                      <div className='default-select' id='default-select'>
                        <select
                          value={infoGerarCertificado.turma}
                          required
                          onChange={(e) => {
                            const val = e.target.value
                            buscarTurma(val)
                            setGerarCertificado(prevState => {
                              return { ...prevState, turma: val }
                            })
                          }}
                        >
                          <option key='selecionar' value='selecionar'>Selecione uma Turma</option>
                          {turmas.map((turma, index) => (
                            <option key={index} value={turma.idTurma}>{turma.nome}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='mt-30 mb-30'>
                <button type='submit' className='genric-btn primary radius'>Gerar Certificado</button>
              </div>

              {error && (
                <div className='alert alert-warning' role='alert'>
                  Por favor, preencha todos os campos.
                </div>
              )}
            </form>
          </div>
        </div>
      </section>
    </>
  )
}

export default GerarCertificado

// @ts-nocheck
import React, { useContext, useState, useEffect } from 'react'
import t from 'prop-types'
import { CategoriaContext } from 'contexts/categorias'
import api from 'services/api'
import AddModalDefault from 'utils/modal-default'

const ModalEdtCategoria = ({ modal, toggle, categoriaAtual }) => {
  const { listarCategorias } = useContext(CategoriaContext)
  const [categoriaInfo, setCategoriaInfo] = useState({
    nome: '',
    descricao: ''
  })

  useEffect(() => {
    const categoria = {
      nome: categoriaAtual.nome,
      descricao: categoriaAtual.descricao
    }
    setCategoriaInfo(categoria)
  }, [categoriaAtual])

  const handleEditar = (e) => {
    e.preventDefault()
    e.target.reset()

    api.put(`/categorias/${categoriaAtual.idCategoria}`, categoriaInfo)
      .then((response) => {
        listarCategorias()
        setCategoriaInfo({
          nome: '',
          descricao: ''
        })
        toggle()
      })
  }

  const handleFocus = (e) => {
    e.target.placeholder = ''
  }

  const handleBlur = (e, placeholder) => {
    e.target.placeholder = placeholder
  }

  return (
    <>
      <AddModalDefault modal={modal} toggle={toggle} titulo='Editar Categoria' handleAction={handleEditar} btnLabel='Editar'>
        <div className='form-group form-inline'>
          <div className='form-group'>
            <input
              type='text'
              className='form-control'
              style={{ width: '400px' }}
              id='edt-nome-categoria'
              placeholder='Nome da Categoria'
              value={categoriaInfo.nome || ''}
              required
              onFocus={handleFocus}
              onBlur={(e) => handleBlur(e, 'Nome da Categoria')}
              onChange={(e) => {
                const val = e.target.value
                setCategoriaInfo(prevState => {
                  return { ...prevState, nome: val }
                })
              }}
            />
          </div>
        </div>

        <div className='form-group form-inline'>
          <div className='form-group'>
            <textarea
              type='text'
              className='form-control'
              style={{ width: '400px' }}
              id='edt-descricao-categoria'
              placeholder='Descrição da Categoria'
              value={categoriaInfo.descricao || ''}
              rows='4'
              required
              onFocus={handleFocus}
              onBlur={(e) => handleBlur(e, 'Descrição da Categoria')}
              onChange={(e) => {
                const val = e.target.value
                setCategoriaInfo(prevState => {
                  return { ...prevState, descricao: val }
                })
              }}
            />
          </div>
        </div>
      </AddModalDefault>
    </>
  )
}

ModalEdtCategoria.propTypes = {
  modal: t.bool,
  toggle: t.func,
  categoriaAtual: t.object
}

export default ModalEdtCategoria

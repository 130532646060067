// @ts-nocheck
import React, { useContext } from 'react'
import t from 'prop-types'
import { TurmaContext } from 'contexts/turmas'
import api from 'services/api'
import ModalDefault from 'utils/modal-default'

const ModalDelTurma = ({ modal, toggle, turmaAtual }) => {
  const { listarTurmas } = useContext(TurmaContext)

  const handleDeletar = (e) => {
    e.preventDefault()

    api.delete(`/turmas/${turmaAtual.idTurma}`)
      .then((response) => {
        listarTurmas()
        toggle()
      })
  }

  return (
    <>
      <ModalDefault
        modal={modal}
        toggle={toggle}
        titulo='Deletar Turma'
        btnLabel='Deletar'
        handleAction={handleDeletar}
      >
        <p>
          Deseja deletar a turma {turmaAtual.nome}?
        </p>
      </ModalDefault>
    </>
  )
}

ModalDelTurma.propTypes = {
  modal: t.bool,
  toggle: t.func,
  turmaAtual: t.object
}

export default ModalDelTurma

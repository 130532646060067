import React, { useEffect, useContext } from 'react'
import { BannerDefault } from 'ui'
import { Link } from 'react-router-dom'
import { HOME, MANTER_CURSO, AMBIENTE_INTERNO } from 'routes'

import { CategoriaContext } from 'contexts/categorias'

import { TabelaCategoria } from 'pages/ambiente-interno/manter-categoria'

const ManterCurso = () => {
  // @ts-ignore
  const { categorias, listarCategorias } = useContext(CategoriaContext)

  useEffect(() => {
    listarCategorias()
  }, [listarCategorias])

  return (
    <>
      <BannerDefault>
        <h1 className='text-white'>
          Ambiente Interno
        </h1>
        <p className='text-white link-nav'>
          <Link to={HOME}>Home</Link>
          <span className='lnr lnr-arrow-right' />
          <Link to={AMBIENTE_INTERNO}>Ambiente Interno</Link>
          <span className='lnr lnr-arrow-right' />
          <Link to={MANTER_CURSO}>Manter Categorias</Link>
        </p>
      </BannerDefault>

      <section className='post-content-area single-post-area'>
        <div className='container'>
          <TabelaCategoria
            categorias={categorias}
          />
        </div>
      </section>
    </>
  )
}

export default ManterCurso

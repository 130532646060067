/* eslint-disable import/no-duplicates */
// @ts-nocheck
import React, { useState, useContext, useEffect } from 'react'
import { BannerDefault } from 'ui'
import { Link, useParams } from 'react-router-dom'
import InputMask from 'react-input-mask'

import { HOME, INSCRICAO } from 'routes'
import { CursoContext } from 'contexts/cursos'
import { formatTel } from 'utils/mask'
import api from 'services/api'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Alert'

const Inscricao = () => {
  const { idCurso } = useParams()
  const { listarCursos, cursos, buscarCurso } =
    useContext(CursoContext)

  const [inscricao, setInscricao] = useState({
    nome: '',
    email: '',
    replyTo: '',
    telefone: '',
    faculdade: '',
    curso: idCurso !== ':idCurso' ? idCurso : '',
    textParallel: '',
    cursoOutros: ''
  })

  useEffect(() => {
    listarCursos()
  }, [listarCursos])

  useEffect(() => {
    if (idCurso !== ':idCurso') {
      buscarCurso(idCurso)
    }
  }, [buscarCurso, idCurso])

  const handleFocus = (e) => {
    e.target.placeholder = ''
  }

  const handleBlur = (e, placeholder) => {
    e.target.placeholder = placeholder
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const body = {
      nome: inscricao.nome,
      email: 'contato@parallel.com.br',
      replyTo: inscricao.email,
      telefone: inscricao.telefone,
      faculdade: inscricao.faculdade,
      curso: inscricao.curso,
      textParallel: inscricao.textParallel,
      cursoOutros: inscricao.cursoOutros
    }
    api.post('/inscrever', body).then((response) => {
      setInscricao({
        nome: '',
        email: '',
        replyTo: '',
        telefone: '',
        faculdade: '',
        curso: idCurso !== ':idCurso' ? idCurso : '',
        textParallel: '',
        cursoOutros: ''
      })
    })
    setShow(false)
    e.target.reset()
  }

  const [show, setShow] = useState(true)

  return (
    <>
      <BannerDefault>
        <h1 className='text-white'>Inscrição</h1>
        <p className='text-white link-nav'>
          <Link to={HOME}>Home</Link>
          <span className='lnr lnr-arrow-right' />
          <Link to={INSCRICAO}>Inscrição</Link>
        </p>
      </BannerDefault>

      <section className='post-content-area single-post-area py-0'>
        <div className='container'>
          <div className='comment-form m-0'>
            <h3 className='m-0'>Pré-Inscrição</h3>

            <form name='preInscricao' method='post' onSubmit={handleSubmit}>
              <div className='container py-5'>
                <label
                  className='text-left w-100 mt-20'
                  style={{ color: '#000' }}
                  htmlFor='inscricao-nome'
                >
                  Nome Completo*
                </label>
                <input
                  name='nome'
                  type='text'
                  className='form-control w-100 border border-dark'
                  id='inscricao-nome'
                  required
                  onFocus={handleFocus}
                  onChange={(e) => {
                    const val = e.target.value
                    setInscricao((prevState) => {
                      return { ...prevState, nome: val }
                    })
                  }}
                />
                <div className='row'>
                  <div className='col-sm'>
                    <label
                      className='text-left w-100 mt-20'
                      style={{ color: '#000' }}
                      htmlFor='inscricao-email'
                    >
                      Email*
                    </label>
                    <input
                      name='email'
                      type='email'
                      className='form-control w-100 border border-dark'
                      id='incricao-email'
                      required
                      onFocus={handleFocus}
                      onChange={(e) => {
                        const val = e.target.value
                        setInscricao((prevState) => {
                          return { ...prevState, email: val }
                        })
                      }}
                    />
                  </div>
                  <div className='col-sm'>
                    <label
                      className='text-left w-100 mt-20'
                      style={{ color: '#000' }}
                      htmlFor='inscricao-email'
                    >
                      Telefone*
                    </label>
                    <InputMask
                      mask='(99) 9 9999-9999'
                      name='telefone'
                      type='text'
                      className='form-control w-100 border border-dark'
                      id='inscricao-tel'
                      placeholder='(99) 9 9999-9999'
                      value={inscricao.telefone || ''}
                      required
                      onFocus={handleFocus}
                      onBlur={(e) => handleBlur(e, '(99) 9 9999-9999')}
                      onChange={(e) => {
                        const val = e.target.value
                        setInscricao((prevState) => {
                          return { ...prevState, telefone: formatTel(val) }
                        })
                      }}
                    />
                  </div>
                </div>
                <label
                  className='text-left w-100 mt-20'
                  style={{ color: '#000' }}
                  htmlFor='inscricao-email'
                >
                  Faculdade/Curso*
                </label>
                <input
                  name='faculdade'
                  type='text'
                  className='form-control w-100 border border-dark'
                  id='inscricao-faculdade'
                  onFocus={handleFocus}
                  onChange={(e) => {
                    const val = e.target.value
                    setInscricao((prevState) => {
                      return { ...prevState, faculdade: val }
                    })
                  }}
                />
                <label
                  className='text-left w-100 mt-20'
                  style={{ color: '#000' }}
                  htmlFor='inscricao-email'
                >
                  Qual curso você gostaria de adquirir?*
                </label>
                <div className='border border-dark'>
                  <select
                    className='custom-select w-100 align-self-center border-0 rounded-0'
                    name='curso'
                    value={inscricao.curso || ''}
                    required
                    onChange={(e) => {
                      const val = e.target.value
                      setInscricao((prevState) => {
                        return { ...prevState, curso: val }
                      })
                    }}
                  >
                    <option
                      key='selecionar'
                      value=''
                      className='font-weight-normal border border-dark'
                    >
                      Selecione
                    </option>
                    {cursos.map((curso, index) => (
                      <option key={index} value={curso.idCurso}>
                        {curso.nome}
                      </option>
                    ))}
                  </select>
                </div>

                <label
                  className='text-left w-100 mt-20'
                  style={{ color: '#000' }}
                  htmlFor='inscricao-email'
                >
                  Como conheceu a Parallel?
                </label>
                <input
                  name='textParallel'
                  type='text'
                  className='form-control w-100 border border-dark'
                  id='text-parallel'
                  onFocus={handleFocus}
                  onChange={(e) => {
                    const val = e.target.value
                    setInscricao((prevState) => {
                      return { ...prevState, textParallel: val }
                    })
                  }}
                />

                <label
                  className='text-left w-100 mt-20'
                  style={{ color: '#000' }}
                  htmlFor='inscricao-email'
                >
                  Qual outro curso você gostaria de ter na Parallel?
                </label>
                <input
                  name='cursoOutros'
                  type='text'
                  className='form-control w-100 border border-dark'
                  id='curso-outros'
                  onFocus={handleFocus}
                  onChange={(e) => {
                    const val = e.target.value
                    setInscricao((prevState) => {
                      return { ...prevState, cursoOutros: val }
                    })
                  }}
                />
              </div>

              {!show && (
                <Alert variant='success'>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      position: 'relative'
                    }}
                  >
                    Obrigado! Entraremos em contato em breve!
                    <Button
                      onClick={() => setShow(true)}
                      style={{
                        margin: '0',
                        padding: '0',
                        right: '0',
                        position: 'absolute',
                        cursor: 'pointer'
                      }}
                    >
                      &times;
                    </Button>
                  </div>
                </Alert>
              )}

              <button type='submit' id='btn' className='genric-btn primary'>
                Enviar
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  )
}

export default Inscricao

// @ts-nocheck
import React, { useState, useContext } from 'react'
import { BannerDefault } from 'ui'
import { Link } from 'react-router-dom'
import { HOME, LOGIN, AUTENTICAR } from 'routes'
import { AuthContext } from 'contexts/auth'
import api from 'services/api'
import { set } from 'idb-keyval'

const Login = () => {
  const { login } = useContext(AuthContext)

  const [userInfo, setUserInfo] = useState({
    usuario: '',
    senha: ''
  })

  const [error, setError] = useState(false)
  const [load, setLoad] = useState(false)

  const handleLogin = (e) => {
    e.preventDefault()
    setError(false)
    setLoad(true)
    const { usuario, senha } = userInfo

    api.post(AUTENTICAR, { usuario, senha })
      .then((response) => {
        if (!response.data.message) {
          set('usuario', response.data.usuario)
          set('token', response.data.token)
          login({
            isUserLoggedIn: true,
            user: response.data.usuario,
            token: response.data.token
          })
        } else {
          login({
            isUserLoggedIn: false,
            user: null,
            token: null
          })
          setLoad(false)
          setError(true)
        }
      })
  }

  const handleFocus = (e) => {
    e.target.placeholder = ''
  }

  const handleBlur = (e, placeholder) => {
    e.target.placeholder = placeholder
  }

  return (
    <>
      <BannerDefault>
        <h1 className='text-white'>
          Ambiente Interno
        </h1>
        <p className='text-white link-nav'>
          <Link to={HOME}>Home</Link>
          <span className='lnr lnr-arrow-right' />
          <Link to={LOGIN}>Login</Link>
        </p>
      </BannerDefault>

      <section className='post-content-area single-post-area py-3' style={{ justifyContent: 'center' }}>
        <div className='container'>
          <div className='comment-form'>
            <h4>Login</h4>
            <form onSubmit={handleLogin}>
              <div className='form-group form-inline' style={{ justifyContent: 'center' }}>
                <div className='form-group col-lg-6 col-md-12 usuario'>
                  <input
                    type='text'
                    className='form-control'
                    id='usuario'
                    placeholder='Digite seu usuário'
                    value={userInfo.usuario}
                    required
                    onFocus={handleFocus}
                    onBlur={(e) => handleBlur(e, 'Digite seu usuário')}
                    onChange={(e) => {
                      const val = e.target.value
                      setUserInfo(prevState => {
                        return { ...prevState, usuario: val }
                      })
                    }}
                  />
                </div>
              </div>

              <div className='form-group form-inline' style={{ justifyContent: 'center' }}>
                <div className='form-group col-lg-6 col-md-12 senha'>
                  <input
                    type='password'
                    className='form-control'
                    id='senha'
                    placeholder='Digite a sua senha'
                    value={userInfo.senha}
                    required
                    onFocus={handleFocus}
                    onBlur={(e) => handleBlur(e, 'Digite a sua senha')}
                    onChange={(e) => {
                      const val = e.target.value
                      setUserInfo(prevState => {
                        return { ...prevState, senha: val }
                      })
                    }}
                  />
                </div>
              </div>

              <button type='submit' className='genric-btn primary'>Enviar</button>
            </form>

            {load && (
              <div className='spinner-border mt-30' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            )}

            {error && (
              <div className='alert alert-danger mt-30' role='alert'>
                Usuário/Senha inválido!
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default Login

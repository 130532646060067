import React from 'react'

const Footer = () => {
  return (
    <footer className='footer-area section-gap'>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-lg-5 col-md-6 col-sm-6 container-fluid'>
            <div className='single-footer-widget'>
              <h3 style={{color:"white", marginBottom:10}}>PARALLEL</h3>
              <p style={{ alignItems: 'center', color: 'whitesmoke' , fontSize: 16, textAlign: 'justify' }}>
                A Parallel Consulting &amp; Training foi fundada em 2010 com o
                objetivo de oferecer soluções (serviços de consultoria e
                treinamentos), no segmento de Tecnologia da Informação,
                personalizadas de acordo com as necessidades do cliente. A nossa
                equipe é constituída por profissionais com vasta experiência nas
                mais diversas vertentes em TI, possibilitando soluções
                criativas, sintonizando um paralelismo entre recursos e
                resultados. Selecionados através de históricos bem sucedidos, em
                projetos de grande relevância, nossos profissionais são
                destaques nas posições ocupadas nas mais diversas áreas
                tecnológicas.
              </p>
              <p style={{color: 'whitesmoke'}} className='footer-text'>
                Copyright&copy;
                <script>document.write(new Date().getFullYear())</script>
                All rights reserved | This template is made with
                <i className='fa fa-heart-o' aria-hidden='true' />
                by
                <a
                  href='https://colorlib.com'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Colorlib
                </a>
              </p>
            </div>
          </div>

          <div className='col-lg-3 col-md-6 col-sm-6 social-widget container-fluid'>
            <div className='single-footer-widget py-5'>
              <h4>Apoio</h4>
              <div className='row align-items-center justify-content-center'>
                <div className='col single-brand justify-content-center'>
                  <img
                    className='mx-auto'
                    src='img/about/Fapesb.jpg'
                    alt=''
                    width='120'
                    height='85'
                  />
                </div>
                <div className='col single-brand justify-content-center'>
                  <img
                    className='mx-auto'
                    src='img/about/finep.jpg'
                    alt=''
                    width='120'
                    height='85'
                  />
                </div>
              </div>
            </div>
            <div className='single-footer-widget py-2'>
              <h4>Siga-nos</h4>
              <div className='footer-social d-flex align-items-center'>
                <a
                  href='https://www.facebook.com/ParallelTecnologia/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <i className='fa fa-facebook' />
                </a>
                <a
                  href='https://www.instagram.com/paralleltecnologia/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <i className='fa fa-instagram' />
                </a>
                <a
                  href='https://api.whatsapp.com/send?phone=5571985553564'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <i className='fa fa-whatsapp' />
                </a>
                <a
                  href='https://www.linkedin.com/company/parallel-consulting-&-training/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <i className='fa fa-linkedin' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer

import React, { useEffect, useContext } from 'react'
import { BannerDefault } from 'ui'
import { HOME, MANTER_TURMA, VIEW_TURMA } from 'routes'
import { Link, useParams } from 'react-router-dom'
import { TurmaContext } from 'contexts/turmas'
import { formatDate } from 'utils/mask'

const ViewTurma = () => {
  // @ts-ignore
  const { idTurma } = useParams()
  // @ts-ignore
  const { buscarTurma, turmaAtual } = useContext(TurmaContext)

  useEffect(() => {
    buscarTurma(idTurma)
  }, [buscarTurma, idTurma])

  return (
    <>
      <BannerDefault>
        <h1 className='text-white'>
          Ambiente Interno - Visualizar Turma
        </h1>
        <p className='text-white link-nav'>
          <Link to={HOME}>Home</Link>
          <span className='lnr lnr-arrow-right' />
          <Link to={MANTER_TURMA}>Manter Turma</Link>
          <span className='lnr lnr-arrow-right' />
          <Link to={VIEW_TURMA}>Visualizar Turma</Link>
        </p>
      </BannerDefault>

      <section className='post-content-area single-post-area'>
        <div className='container'>

          <div className='title text-center'>
            <h1 className='mb-40'>Visualizar Turma</h1>
          </div>

          <h3 className='mb-30'>{turmaAtual.nome}</h3>

          <h4 className='mb-10'>Data de Inicio da Turma</h4>
          <p className='mb-30'>{formatDate(turmaAtual.dataInicio)}</p>

          <h4 className='mb-10'>Data de Conclusão da Turma</h4>
          <p className='mb-30'>{formatDate(turmaAtual.dataFim)}</p>

          <h4 className='mb-10'>Curso</h4>
          <p className='mb-30'>{turmaAtual.curso !== undefined ? turmaAtual.curso.nome : ''}</p>
        </div>
      </section>
    </>
  )
}

export default ViewTurma

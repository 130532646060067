// @ts-nocheck
import React, { useState, useContext } from 'react'
import t from 'prop-types'
import api from 'services/api'
import { CategoriaContext } from 'contexts/categorias'
import AddModalDefault from 'utils/modal-default'

const ModalAddCategoria = ({ modal, toggle }) => {
  const { listarCategorias } = useContext(CategoriaContext)
  const [categoriaInfo, setCategoriaInfo] = useState({
    nome: '',
    descricao: ''
  })

  const handleCadastrar = (e) => {
    e.preventDefault()
    e.target.reset()

    api.post('/categorias', categoriaInfo)
      .then((response) => {
        listarCategorias()
        setCategoriaInfo({
          nome: '',
          descricao: ''
        })
        toggle()
      })
  }

  const handleFocus = (e) => {
    e.target.placeholder = ''
  }

  const handleBlur = (e, placeholder) => {
    e.target.placeholder = placeholder
  }

  return (
    <>
      <AddModalDefault
        modal={modal}
        toggle={toggle}
        titulo='Adicionar Nova Categoria'
        handleAction={handleCadastrar}
        btnLabel='Adicionar'
      >
        <div className='form-group form-inline'>
          <div className='form-group'>
            <input
              type='text'
              className='form-control'
              style={{ width: '400px' }}
              id='add-nome-catogoria'
              placeholder='Nome da Categoria'
              value={categoriaInfo.nome}
              required
              onFocus={handleFocus}
              onBlur={(e) => handleBlur(e, 'Nome da Categoria')}
              onChange={(e) => {
                const val = e.target.value
                setCategoriaInfo(prevState => {
                  return { ...prevState, nome: val }
                })
              }}
            />
          </div>
        </div>

        <div className='form-group form-inline'>
          <div className='form-group'>
            <textarea
              type='text'
              className='form-control'
              style={{ width: '400px' }}
              id='add-descricao-categoria'
              placeholder='Desrição da Categoria'
              value={categoriaInfo.descricao}
              rows='4'
              required
              onFocus={handleFocus}
              onBlur={(e) => handleBlur(e, 'Desrição da Categoria')}
              onChange={(e) => {
                const val = e.target.value
                setCategoriaInfo(prevState => {
                  return { ...prevState, descricao: val }
                })
              }}
            />
          </div>
        </div>
      </AddModalDefault>
    </>
  )
}

ModalAddCategoria.propTypes = {
  modal: t.bool,
  toggle: t.func
}

export default ModalAddCategoria

import React from 'react'
import { BannerDefault } from 'ui'
import { Link } from 'react-router-dom'

const BannerCurso = () => {
  return (
    <BannerDefault>
      <h1 className='text-white'>
        Cursos

      </h1>
      <p className='text-white link-nav'>
        <Link to='/'>Home</Link>
        <span className='lnr lnr-arrow-right' />
        <Link to='/'>Cursos</Link>

      </p>

    </BannerDefault>
  )
}

export default BannerCurso

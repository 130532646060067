import React from 'react'
import { HashRouter, Switch, Route } from 'react-router-dom'
import TurmaProvider from 'contexts/turmas'
import CursoProvider from 'contexts/cursos'
import CategoriaProvider from 'contexts/categorias'
import AuthProvider from 'contexts/auth'

import { MainPage } from 'pages/main'
const App = () => {
  return (
    <AuthProvider>
      <TurmaProvider>
        <CategoriaProvider>
          <CursoProvider>
            <HashRouter>
              <Switch>
                <Route component={MainPage} />
              </Switch>
            </HashRouter>
          </CursoProvider>
        </CategoriaProvider>
      </TurmaProvider>
    </AuthProvider>
  )
}

export default App

// @ts-nocheck
import React, { Suspense, useContext, useEffect } from 'react'
import { Switch } from 'react-router-dom'

import { HOME, CURSOS, CONTATO, INSCRICAO, AGENDA, LOGIN, MANTER_CURSO, VIEW_CURSO, AMBIENTE_INTERNO, MANTER_CATEGORIA, VIEW_CATEGORIA, MANTER_TURMA, VIEW_TURMA, AREA_ADMIN, GERENCIAR_CERTIFICADOS, GERAR_CERTIFICADO, ENVIAR_CERTIFICADO, PAGAMENTO } from 'routes'

import Footer from 'pages/main/footer'

import { PaginaInicial } from 'pages/pagina-inicial'
import { Cursos } from 'pages/cursos'
import { Contato } from 'pages/contato'
import { Inscricao } from 'pages/inscricao'
import { Agenda } from 'pages/agenda'
import { Login } from 'pages/login'
import { ManterCurso, ViewCurso } from 'pages/ambiente-interno/manter-curso'
import { AmbienteInterno } from 'pages/ambiente-interno'
import { ManterCategoria, ViewCategoria } from 'pages/ambiente-interno/manter-categoria'
import Header from 'pages/main/header'
import ManterTurma from 'pages/ambiente-interno/manter-turma/turma'
import ViewTurma from 'pages/ambiente-interno/manter-turma/view-turma'
import { PublicRoute, PrivateRoute } from 'route-component'
import { get } from 'idb-keyval'
import { AuthContext } from 'contexts/auth'
import { AreaAdmin } from 'pages/area-admin'
import { GerarCertificado, GerenciarCertificados, EnviarCertificado } from 'pages/ambiente-interno/gerarenciar-certificado'
import { Pagamento } from 'pages/pagamento'

import { CursoContext } from 'contexts/cursos'
import { CategoriaContext } from 'contexts/categorias'

const MainPage = () => {
  const { listarCursos,  } = useContext(CursoContext)
  const { listarCategorias, } = useContext(CategoriaContext)

  useEffect(() => {
    listarCursos()
    listarCategorias()
  }, [listarCursos, listarCategorias])



  const { setUserLogin } = useContext(AuthContext)
  useEffect(() => {
    get('usuario')
      .then((usuario) => {
        if (usuario) {
          setUserLogin({
            isUserLoggedIn: true,
            user: usuario
          })
        }
      })
  }, [setUserLogin])
  return (
    <>
      <Header />

      <Suspense fallback={<h1>Carregando...</h1>}>
        <Switch>
          <PublicRoute path={HOME} exact component={PaginaInicial} />
          <PublicRoute path={CURSOS} component={Cursos} />
          <PublicRoute path={CONTATO} component={Contato} />
          <PublicRoute path={INSCRICAO} component={Inscricao} />
          <PublicRoute path={PAGAMENTO} component={Pagamento} />
          <PublicRoute path={AGENDA} component={Agenda} />
          <PublicRoute path={AREA_ADMIN} component={AreaAdmin} />
          <PublicRoute restricted path={LOGIN} component={Login} />
          <PrivateRoute path={AMBIENTE_INTERNO} component={AmbienteInterno} />
          <PrivateRoute path={MANTER_CURSO} component={ManterCurso} />
          <PrivateRoute path={VIEW_CURSO} component={ViewCurso} />
          <PrivateRoute path={MANTER_CATEGORIA} component={ManterCategoria} />
          <PrivateRoute path={VIEW_CATEGORIA} component={ViewCategoria} />
          <PrivateRoute path={MANTER_TURMA} component={ManterTurma} />
          <PrivateRoute path={VIEW_TURMA} component={ViewTurma} />
          <PrivateRoute path={GERENCIAR_CERTIFICADOS} component={GerenciarCertificados} />
          <PrivateRoute path={GERAR_CERTIFICADO} component={GerarCertificado} />
          <PrivateRoute path={ENVIAR_CERTIFICADO} component={EnviarCertificado} />
        </Switch>
      </Suspense>
      <Footer />
    </>
  )
}

export default MainPage

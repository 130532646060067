/* eslint-disable import/no-duplicates */
// @ts-nocheck
import React, { useState, useContext, useEffect } from 'react'
import { BannerDefault } from 'ui'
import { Link } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Alert'
import InputMask from 'react-input-mask'

import { HOME, PAGAMENTO } from 'routes'
import { CursoContext } from 'contexts/cursos'
import { formatTel } from 'utils/mask'

const Pagamento = () => {
  const { listarCursos, cursos, buscarCurso, cursoAtual } =
    useContext(CursoContext)

  const [pagamento, setPagamento] = useState({
    nome: '',
    email: '',
    telefone: '',
    cpf: '',
    curso: ''
  })

  useEffect(() => {
    listarCursos()
  }, [listarCursos])

  const [show, setShow] = useState(true)

  const validarCPF = (e) => {
    var strCPF = pagamento.cpf
    strCPF = strCPF.replace(/[^\d]/g, '')
    var soma = 0
    var resto
    var i = 0

    if (strCPF === '00000000000') {
      e.preventDefault()
      setShow(false)
    }

    for (i = 1; i <= 9; i++) { soma = soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i) }
    resto = (soma * 10) % 11

    if (resto === 10 || resto === 11) resto = 0
    if (resto !== parseInt(strCPF.substring(9, 10))) {
      e.preventDefault()
      setShow(false)
    }

    soma = 0
    for (i = 1; i <= 10; i++) { soma = soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i) }
    resto = (soma * 10) % 11

    if (resto === 10 || resto === 11) resto = 0
    if (resto !== parseInt(strCPF.substring(10, 11))) {
      e.preventDefault()
      setShow(false)
    }
    return true
  }

  const handleFocus = (e) => {
    e.target.placeholder = ''
  }

  const handleBlur = (e, placeholder) => {
    e.target.placeholder = placeholder
  }

  const onChangeReCaptcha = (value) => {
    console.log('Captcha value:', value)
  }

  return (
    <>
      <BannerDefault>
        <h1 className='text-white'>Efetuar Pagamento</h1>
        <p className='text-white link-nav'>
          <Link to={HOME}>Home</Link>
          <span className='lnr lnr-arrow-right' />
          <Link to={PAGAMENTO}>PAGAMENTO</Link>
        </p>
      </BannerDefault>

      <section className='post-content-area single-post-area py-0'>
        <div className='container'>
          <div className='comment-form m-0'>
            <form
              method='post'
              target='pagseguro'
              action='https://pagseguro.uol.com.br/v2/checkout/payment.html'
            >
              <h5 className='mb-30'>Informações Pessoais</h5>
              <input
                name='receiverEmail'
                type='hidden'
                value='contato@parallel.com.br'
              />
              <input name='currency' type='hidden' value='BRL' />
              <div className='row'>
                <div className='col-sm'>
                  <label
                    className='text-left w-100 mt-20'
                    style={{ color: '#000' }}
                    htmlFor='inscricao-email'
                  >
                    Nome Completo*
                  </label>
                  <input
                    type='text'
                    className='form-control w-100 border border-dark'
                    id='inscricao-nome'
                    required
                    onFocus={handleFocus}
                    onChange={(e) => {
                      const val = e.target.value
                      setPagamento((prevState) => {
                        return { ...prevState, nome: val }
                      })
                    }}
                  />
                </div>
                <div className='col-sm'>
                  <label
                    className='text-left w-100 mt-20'
                    style={{ color: '#000' }}
                    htmlFor='inscricao-email'
                  >
                    Email*
                  </label>
                  <input
                    type='email'
                    className='form-control w-100 border border-dark'
                    id='incricao-email'
                    required
                    onFocus={handleFocus}
                    onChange={(e) => {
                      const val = e.target.value
                      setPagamento((prevState) => {
                        return { ...prevState, email: val }
                      })
                    }}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-sm'>
                  <label
                    className='text-left w-100 mt-20'
                    style={{ color: '#000' }}
                    htmlFor='inscricao-email'
                  >
                    Telefone*
                  </label>
                  <InputMask
                    mask='(99) 9 9999-9999'
                    type='text'
                    className='form-control w-100 border border-dark'
                    id='inscricao-tel'
                    placeholder='(99) 9 9999-9999'
                    value={pagamento.telefone || ''}
                    required
                    onFocus={handleFocus}
                    onBlur={(e) => handleBlur(e, '(99) 9 9999-9999')}
                    onChange={(e) => {
                      const val = e.target.value
                      setPagamento((prevState) => {
                        return { ...prevState, telefone: formatTel(val) }
                      })
                    }}
                  />
                </div>
                <div className='col-sm'>
                  <label
                    className='text-left w-100 mt-20'
                    style={{ color: '#000' }}
                    htmlFor='inscricao-email'
                  >
                    CPF*
                  </label>
                  <InputMask
                    mask='999.999.999-99'
                    type='text'
                    className='form-control w-100 border border-dark'
                    id='inscricao-cpf'
                    placeholder='999.999.999-99'
                    onFocus={handleFocus}
                    onBlur={(e) => handleBlur(e, '999.999.999-99')}
                    onChange={(e) => {
                      const val = e.target.value
                      setPagamento((prevState) => {
                        return { ...prevState, cpf: val }
                      })
                    }}
                  />
                </div>
              </div>
              <div className='form-group email justify-content-center mt-50'>
                <h5 className='mb-20'>Sobre o Curso</h5>
                <label
                  className='text-left w-100'
                  style={{ color: '#000' }}
                  htmlFor='inscricao-email'
                >
                  Qual curso você gostaria de adquirir?*
                </label>
                <div className='border border-dark'>
                  <select
                    className='custom-select w-100 align-self-center border-0 rounded-0'
                    value={pagamento.curso || ''}
                    required
                    onChange={(e) => {
                      const val = e.target.value
                      buscarCurso(val)
                      setPagamento((prevState) => {
                        return { ...prevState, curso: val }
                      })
                    }}
                  >
                    <option key='selecionar' value=''>
                      Selecione um Curso
                    </option>
                    {cursos.map((curso, index) => (
                      <option key={index} value={curso.idCurso}>
                        {curso.nome}
                      </option>
                    ))}
                  </select>
                </div>
                <input name='itemId1' type='hidden' value='1' />
                <input
                  name='itemDescription1'
                  type='hidden'
                  value={cursoAtual.nome || ''}
                />
                <input
                  name='itemAmount1'
                  type='hidden'
                  value={
                    cursoAtual.preco !== undefined
                      ? cursoAtual.preco.toFixed(2)
                      : '0.00'
                  }
                />
                <input name='itemQuantity1' type='hidden' value='1' />

                <input
                  name='shippingAddressRequired'
                  type='hidden'
                  value='false'
                />
                <input
                  name='senderAreaCode'
                  type='hidden'
                  value={pagamento.telefone.slice(1, 3) || ''}
                />
                <input
                  name='senderPhone'
                  type='hidden'
                  value={pagamento.telefone.split(' ')[1] || ''}
                />
                <input
                  name='senderEmail'
                  type='hidden'
                  value={pagamento.email || ''}
                />
              </div>

              <div className='form-group form-inline'>
                <div className='form-group col-lg-6 col-md-12 name'>
                  <ReCAPTCHA
                    sitekey='6Lfa2v0UAAAAAHBhIAVYAZkeYwEgh3sRIRl_JSln'
                    onChange={onChangeReCaptcha}
                    hl='pt-BR'
                  />
                </div>
              </div>

              {!show && (
                <Alert variant='danger'>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      position: 'relative'
                    }}
                  >
                    Por favor, insira um CPF válido para prosseguir!
                    <Button
                      onClick={() => setShow(true)}
                      style={{
                        margin: '0',
                        padding: '0',
                        right: '0',
                        position: 'absolute',
                        cursor: 'pointer'
                      }}
                    >
                      &times;
                    </Button>
                  </div>
                </Alert>
              )}

              <input
                onClick={validarCPF}
                alt='Pague com PagSeguro'
                name='submit'
                type='image'
                src='../../img/btnPagSeguro.png'
                className='rounded '
              />
            </form>
          </div>
        </div>
      </section>
    </>
  )
}

export default Pagamento

// @ts-nocheck
import React, { useState, useEffect, useContext } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import t from 'prop-types'
import api from 'services/api'
import { CursoContext } from 'contexts/cursos'
import { CategoriaContext } from 'contexts/categorias'
import { formatReal } from 'utils/mask'
import TabelaAddEmenta from './ementas/tabela-ementa'
import ModalEdtEmenta from './ementas/edt-ementa'

const ModalAddCurso = ({ modal, nestedModal, toggle, toggleNested }) => {
  const { listarCategorias, categorias } = useContext(CategoriaContext)
  const { listarCursos } = useContext(CursoContext)

  const [cursoInfo, setCursoInfo] = useState({
    nome: '',
    descricao: '',
    preco: '',
    categoria: '',
    modalidade: '',
    publicoAlvo: '',
    cargaHoraria: '',
    objetivo: '',
    metodologia: ''
  })
  const [ementaInfo, setEmentaInfo] = useState({ idEmenta: 0, item: '' })
  const [ementas, setEmentas] = useState([])
  const [ementaAtual, setEmentaAtual] = useState({})
  const [openEdtModal, setOpenEdtModal] = useState(false)
  const toggleEdt = () => setOpenEdtModal(!openEdtModal)

  const [error, setError] = useState(false)

  useEffect(() => {
    listarCategorias()
  }, [listarCategorias])

  const handleAddEmenta = (ementa) => {
    setError(false)
    setEmentaInfo((prevState) => {
      return { idEmenta: prevState.idEmenta++, item: ementa.item }
    })
    setEmentas((state) => {
      const list = state.concat(ementaInfo)
      return list
    })
    toggleNested()
  }

  const handleEditar = (e, id, indice) => {
    e.preventDefault()
    setEmentaAtual(ementas.find((item, index) => indice === index))
    toggleEdt()
  }

  const handleEditarEmenta = (ementaAtualizada, ementa) => {
    const indice = ementas.indexOf(ementa)
    setEmentas(
      ementas.map((item, index) => {
        if (indice === index) {
          return ementaAtualizada
        }
        return item
      })
    )
  }

  const handleDeletarEmenta = (e, id, indice) => {
    e.preventDefault()
    setEmentas(ementas.filter((item, index) => index !== indice))
  }

  const handleCadastrar = (e) => {
    e.preventDefault()
    e.target.reset()
    setError(false)

    const curso = {
      nome: cursoInfo.nome,
      descricao: cursoInfo.descricao,
      preco: Number(
        cursoInfo.preco.split(' ')[1].replace('.', '').replace(',', '.')
      ),
      categoria: cursoInfo.categoria,
      modalidade: cursoInfo.modalidade,
      publicoAlvo: cursoInfo.publicoAlvo,
      cargaHoraria: cursoInfo.cargaHoraria,
      objetivo: cursoInfo.objetivo,
      metodologia: cursoInfo.metodologia
    }

    if (
      ementas.length > 0 &&
      curso.nome &&
      curso.descricao &&
      curso.preco &&
      curso.categoria &&
      curso.modalidade &&
      curso.publicoAlvo &&
      curso.cargaHoraria &&
      curso.objetivo &&
      curso.metodologia
    ) {
      api.post('/cursos', curso).then((response) => {
        api.post('/ementas', {
          ementas: ementas,
          idCurso: response.data.idCurso
        })

        listarCursos()
        setCursoInfo({
          nome: '',
          descricao: '',
          preco: '',
          categoria: '',
          modalidade: '',
          publicoAlvo: '',
          cargaHoraria: '',
          objetivo: '',
          metodologia: ''
        })
        setEmentas([])
        toggle()
      })
    } else {
      setError(true)
    }
  }

  const handleFocus = (e) => {
    e.target.placeholder = ''
  }

  const handleBlur = (e, placeholder) => {
    e.target.placeholder = placeholder
  }

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <form onSubmit={handleCadastrar}>
          <ModalHeader toggle={toggle}>Adicionar Curso</ModalHeader>
          <ModalBody>
            <div className='form-group form-inline'>
              <div className='form-group'>
                <input
                  type='text'
                  className='form-control'
                  style={{ width: '400px' }}
                  id='add-nome-curso'
                  placeholder='Nome do Curso'
                  value={cursoInfo.nome}
                  required
                  onFocus={handleFocus}
                  onBlur={(e) => handleBlur(e, 'Nome do Curso')}
                  onChange={(e) => {
                    const val = e.target.value
                    setCursoInfo((prevState) => {
                      return { ...prevState, nome: val }
                    })
                  }}
                />
              </div>
            </div>

            <div className='form-group form-inline'>
              <div className='form-group'>
                <textarea
                  className='form-control'
                  style={{ width: '400px' }}
                  id='add-descricao-curso'
                  placeholder='Descrição do Curso'
                  value={cursoInfo.descricao}
                  rows={4}
                  required
                  onFocus={handleFocus}
                  onBlur={(e) => handleBlur(e, 'Descrição do Curso')}
                  onChange={(e) => {
                    const val = e.target.value
                    setCursoInfo((prevState) => {
                      return { ...prevState, descricao: val }
                    })
                  }}
                />
              </div>
            </div>

            <div className='form-group form-inline'>
              <div className='form-group'>
                <input
                  type='text'
                  className='form-control'
                  style={{ width: '400px' }}
                  id='add-preco-curso'
                  placeholder='Preço do Curso'
                  value={cursoInfo.preco}
                  required
                  onFocus={handleFocus}
                  onBlur={(e) => handleBlur(e, 'Preço do Curso')}
                  onChange={(e) => {
                    const val = e.target.value
                    setCursoInfo((prevState) => {
                      return { ...prevState, preco: formatReal(val) }
                    })
                  }}
                />
              </div>
            </div>

            <div className='form-group form-inline'>
              <div className='form-group'>
                <div className='title text-left'>
                  <h6 className='mb-10'>Categoria</h6>
                  <div className='default-select' id='default-select'>
                    <select
                      value={cursoInfo.categoria}
                      required
                      onChange={(e) => {
                        const val = e.target.value
                        setCursoInfo((prevState) => {
                          return { ...prevState, categoria: val }
                        })
                      }}
                    >
                      <option key='selecionar' value=''>
                        Selecione uma Categoria
                      </option>
                      {categorias.map((categoria, index) => (
                        <option key={index} value={categoria.idCategoria}>
                          {categoria.nome}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className='form-group form-inline'>
              <div className='form-group'>
                <div className='title text-left'>
                  <h6 className='mb-10'>Modalidade</h6>
                  <div className='default-select' id='default-select'>
                    <select
                      value={cursoInfo.modalidade}
                      required
                      onChange={(e) => {
                        const val = e.target.value
                        setCursoInfo((prevState) => {
                          return { ...prevState, modalidade: val }
                        })
                      }}
                    >
                      <option key='selecionar' value=''>
                        Selecione uma Modalidade
                      </option>
                      <option key='online' value='Online'>
                        Online
                      </option>
                      <option key='presencial' value='Presencial'>
                        Presencial
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className='form-group form-inline'>
              <div className='form-group'>
                <textarea
                  className='form-control'
                  style={{ width: '400px' }}
                  id='add-publico-curso'
                  placeholder='Publico Alvo do Curso'
                  value={cursoInfo.publicoAlvo}
                  required
                  rows={2}
                  onFocus={handleFocus}
                  onBlur={(e) => handleBlur(e, 'Publico Alvo')}
                  onChange={(e) => {
                    const val = e.target.value
                    setCursoInfo((prevState) => {
                      return { ...prevState, publicoAlvo: val }
                    })
                  }}
                />
              </div>
            </div>

            <div className='form-group form-inline'>
              <div className='form-group'>
                <input
                  type='text'
                  className='form-control'
                  style={{ width: '400px' }}
                  id='add-carga-horaria-curso'
                  placeholder='Carga Horaria do Curso'
                  value={cursoInfo.cargaHoraria}
                  required
                  onFocus={handleFocus}
                  onBlur={(e) => handleBlur(e, 'Carga Horaria do Curso')}
                  onChange={(e) => {
                    const val = e.target.value
                    setCursoInfo((prevState) => {
                      return { ...prevState, cargaHoraria: val }
                    })
                  }}
                />
              </div>
            </div>

            <div className='form-group form-inline'>
              <div className='form-group'>
                <input
                  type='text'
                  className='form-control'
                  style={{ width: '400px' }}
                  id='add-objetivo-curso'
                  placeholder='Objetivo'
                  value={cursoInfo.objetivo}
                  required
                  onFocus={handleFocus}
                  onBlur={(e) => handleBlur(e, 'Objetivo')}
                  onChange={(e) => {
                    const val = e.target.value
                    setCursoInfo((prevState) => {
                      return { ...prevState, objetivo: val }
                    })
                  }}
                />
              </div>
            </div>

            <div className='form-group form-inline'>
              <div className='form-group'>
                <textarea
                  className='form-control'
                  style={{ width: '400px' }}
                  id='add-metodologia-curso'
                  placeholder='Metodologia Utilizada'
                  value={cursoInfo.metodologia}
                  required
                  rows={4}
                  onFocus={handleFocus}
                  onBlur={(e) => handleBlur(e, 'Metodologia Utilizada')}
                  onChange={(e) => {
                    const val = e.target.value
                    setCursoInfo((prevState) => {
                      return { ...prevState, metodologia: val }
                    })
                  }}
                />
              </div>
            </div>

            <div className='form-group form-inline'>
              <div className='form-group'>
                <button
                  type='button'
                  className='genric-btn primary radius'
                  onClick={toggleNested}
                >
                  Adicionar Ementas ao Curso
                </button>
              </div>
            </div>

            {ementas.length > 0 && (
              <TabelaAddEmenta
                ementas={ementas}
                handleEditar={handleEditar}
                handleDeletar={handleDeletarEmenta}
              />
            )}

            {error && (
              <div className='alert alert-warning' role='alert'>
                Por favor, preencha todos os campos.
              </div>
            )}

            <Modal isOpen={nestedModal} toggle={toggleNested}>
              <ModalHeader>Adicionar Ementas ao Curso</ModalHeader>
              <ModalBody>
                <div className='form-group form-inline'>
                  <div className='form-group'>
                    <input
                      type='text'
                      className='form-control'
                      style={{ width: '400px' }}
                      id='item-add'
                      placeholder='Digite o item da ementa'
                      value={ementaInfo.item}
                      required
                      onFocus={handleFocus}
                      onBlur={(e) => handleBlur(e, 'Digite o item da ementa')}
                      onChange={(e) => {
                        const val = e.target.value
                        setEmentaInfo((prevState) => {
                          return { ...prevState, item: val }
                        })
                      }}
                    />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <button
                  type='button'
                  className='genric-btn primary radius'
                  onClick={handleAddEmenta}
                >
                  Adicionar
                </button>
                <button
                  type='button'
                  className='genric-btn primary-border radius'
                  onClick={toggleNested}
                >
                  Cancelar
                </button>
              </ModalFooter>
            </Modal>

            <ModalEdtEmenta
              ementa={ementaAtual}
              handleEdtEmenta={handleEditarEmenta}
              openEdtModal={openEdtModal}
              toggleEdtModal={toggleEdt}
            />
          </ModalBody>
          <ModalFooter>
            <button type='submit' className='genric-btn primary radius'>
              Adicionar
            </button>
          </ModalFooter>
        </form>
      </Modal>
    </div>
  )
}

ModalAddCurso.propTypes = {
  modal: t.bool,
  nestedModal: t.bool,
  toggle: t.func,
  toggleNested: t.func
}

export default ModalAddCurso

// @ts-nocheck
import React, { useContext } from 'react'
import t from 'prop-types'
import { CursoContext } from 'contexts/cursos'
import api from 'services/api'
import ModalDefault from 'utils/modal-default'

const ModalDelCurso = ({ modal, toggle, cursoAtual }) => {
  const { listarCursos } = useContext(CursoContext)

  const handleDeletar = (e) => {
    e.preventDefault()
    api.delete(`/cursos/${cursoAtual.idCurso}`)
      .then((response) => {
        listarCursos()
        toggle()
      })
  }

  return (
    <>
      <ModalDefault modal={modal} toggle={toggle} titulo='Deletar Curso' btnLabel='Deletar' handleAction={handleDeletar}>
        <p>
          Deseja deletar o curso {cursoAtual.nome}?
        </p>
      </ModalDefault>
    </>
  )
}

ModalDelCurso.propTypes = {
  modal: t.bool,
  toggle: t.func,
  cursoAtual: t.object
}

export default ModalDelCurso

import React from 'react'
import t from 'prop-types'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const ModalDefault = ({ children, modal, toggle, titulo, btnLabel, handleAction }) => {
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <form onSubmit={handleAction}>
          <ModalHeader toggle={toggle}>{titulo}</ModalHeader>
          <ModalBody>
            {children}
          </ModalBody>
          <ModalFooter>
            <button type='submit' className='genric-btn primary radius'>{btnLabel}</button>
          </ModalFooter>
        </form>
      </Modal>
    </div>
  )
}

ModalDefault.propTypes = {
  titulo: t.string,
  btnLabel: t.string,
  children: t.node,
  handleAction: t.func,
  modal: t.bool,
  toggle: t.func
}

export default ModalDefault

import React, { createContext, useState, useCallback } from 'react'
import t from 'prop-types'
import api from 'services/api'

export const TurmaContext = createContext({})

const Turma = ({ children }) => {
  const [turmas, setTurmas] = useState([])
  const [turmaAtual, setTurmaAtual] = useState({})

  const listarTurmas = useCallback(() => {
    api.get('/turmas')
      .then((response) => {
        setTurmas(response.data)
      })
  }, [])

  const buscarTurma = useCallback((idTurma) => {
    api.get(`/turmas/${idTurma}`)
      .then((response) => {
        setTurmaAtual(response.data)
      })
  }, [])

  return (
    <TurmaContext.Provider value={{
      turmaAtual,
      setTurmaAtual,
      turmas,
      setTurmas,
      listarTurmas,
      buscarTurma
    }}
    >
      {children}
    </TurmaContext.Provider>
  )
}

Turma.propTypes = {
  children: t.node.isRequired
}

export default Turma

import React from 'react'
import { Link } from 'react-router-dom'
import { CURSOS } from 'routes'

const Banner = () => {
  return (
    <section className='banner-area relative blog-home-banner' id='home'>
      <div className='overlay overlay-bg' />
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-center'>
          <div className='about-content blog-header-content col-lg-12'>
            <h1 className='text-white'>
              Consultoria em TI e Capacitação em Desenvolvimento
            </h1>
            <div className='py-3'>
              <Link to={CURSOS} className='primary-btn'>Cursos Online</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Banner

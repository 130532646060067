import React, { useEffect, useState } from 'react'
import t from 'prop-types'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const ModalEdtEmenta = ({ ementa, handleEdtEmenta, openEdtModal, toggleEdtModal }) => {
  const [ementaInfo, setEmentaInfo] = useState({ idEmenta: 0, item: '' })

  useEffect(() => {
    setEmentaInfo(ementa)
  }, [ementa])

  const edtEmenta = () => {
    handleEdtEmenta(ementaInfo, ementa)
    toggleEdtModal()
  }

  const handleFocus = (e) => {
    e.target.placeholder = ''
  }

  const handleBlur = (e, placeholder) => {
    e.target.placeholder = placeholder
  }

  return (
    <Modal isOpen={openEdtModal} toggle={toggleEdtModal}>
      <ModalHeader>Editar Ementa</ModalHeader>
      <ModalBody>
        <div className='form-group form-inline'>
          <div className='form-group'>
            <input
              type='text'
              className='form-control'
              style={{ width: '400px' }}
              id='item-add'
              placeholder='Digite o item da ementa'
              value={ementaInfo.item}
              required
              onFocus={handleFocus}
              onBlur={(e) => handleBlur(e, 'Digite o item da ementa')}
              onChange={(e) => {
                const val = e.target.value
                setEmentaInfo((prevState) => {
                  return { ...prevState, item: val }
                })
              }}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type='button'
          className='genric-btn primary radius'
          onClick={edtEmenta}
        >
          Editar
        </button>
        <button
          type='button'
          className='genric-btn primary-border radius'
          onClick={toggleEdtModal}
        >
          Cancelar
        </button>
      </ModalFooter>
    </Modal>
  )
}

ModalEdtEmenta.propTypes = {
  ementa: t.object,
  handleEdtEmenta: t.func,
  openEdtModal: t.bool,
  toggleEdtModal: t.func
}

export default ModalEdtEmenta
